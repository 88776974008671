import styled from '@emotion/styled';

import { css, hover } from '~/src/css';
import { skin, SkinTheme } from '~/src/theme';

const Button = styled('button')(
  css({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  }),
  skin({
    blackSquare: ((theme: SkinTheme<'blackSquare'>) => css({
      color: 'primary',
      background: 'primary',
      textDecoration: 'none',
      // TODO: [WSG-317] partial 스타일도 치환 가능하도록 skin system 수정할 것
      border: '1px solid #fff',
      ...hover({
        background: theme.primary.color,
        color: theme.primary.background,
      }),
    })),
  }),
);

export default Button;
