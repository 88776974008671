import React from 'react';
import styled from '@emotion/styled';
import { useLocale } from '@devsisters/gatsby-plugin-i18n';

import { skin } from '~/src/theme';
import { css } from '~/src/css';
import { useFrontmatter } from '~/src/frontmatter';
import usePlatform from '~/src/misc/usePlatform';

const appStoreCodes = {
  'en': 'us',
  'ko': 'kr',
  'ja': 'jp',
  'zh-Hant': 'tw',
  'zh-Hans': 'cn',
  'th': 'th',
} as const;

interface DownloadButtonsProps {
  className?: string;
  type: 'small' | 'large',
}

const Container = styled('div')(
  skin({
    blackSquare: css({
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
    }),
  }),
);

const StoreLink = styled('a')(
  skin({
    blackSquare: css({
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
      borderRadius: 80,
      display: 'inline-block',
      height: '100%',
    }),
  }),
);

const StoreBanner = styled('img')(
  skin({
    blackSquare: css({
      width: '100%',
      height: 'auto',
    }),
  }),
);

const DownloadButtons: React.FC<DownloadButtonsProps> = ({
  className,
  type,
}) => {
  const locale = useLocale();
  const { googleAppId, appleAppId, onestoreAppId, onelink, galaxystoreAppId } = useFrontmatter();

  const platform = usePlatform();
  const isIos = platform === 'ios';
  const isAndroid = platform === 'android';
  const isMaybeDesktop = !isAndroid && !isIos;

  const appStoreTarget = appStoreCodes[locale as keyof typeof appStoreCodes] || 'CA';

  return (
    <Container className={className}>
      {appleAppId && (isMaybeDesktop || isIos) && (
        <StoreLink
          target="_blank"
          rel="noopener"
          href={isMaybeDesktop
            ? `https://apps.apple.com/${appStoreTarget}/app/id${appleAppId}`
            : onelink
          }
          className={'appstore-button'}
        >
          <StoreBanner src={
            require(`!@svgr/webpack!file-loader!~/src/images/appstore${isMaybeDesktop && type !== 'small' ? '' : '-mobile'}.svg`).default
          }
          />
        </StoreLink>
      )}
      {googleAppId && (isMaybeDesktop || isAndroid) && (
        <>
          <StoreLink
            target="_blank"
            rel="noopener"
            href={isMaybeDesktop
              ? `https://play.google.com/store/apps/details?id=${googleAppId}`
              : onelink
            }
            className={'playstore-button'}
          >
            <StoreBanner src={
              require(`!@svgr/webpack!file-loader!~/src/images/playstore${isMaybeDesktop && type !== 'small' ? '' : '-mobile'}.svg`).default
            }
            />
          </StoreLink>
          {onestoreAppId && (
            <StoreLink
              target="_blank"
              rel="noopener"
              href={`https://onesto.re/${onestoreAppId}`}
              className={'onestore-button'}
            >
              <StoreBanner
                src={
                  require(`!@svgr/webpack!file-loader!~/src/images/onestore${isMaybeDesktop && type !== 'small' ? '' : '-mobile'}.svg`).default
                }
              />
            </StoreLink>
          )}
          {galaxystoreAppId && (
            <StoreLink
              target="_blank"
              rel="noopener"
              href={`https://galaxystore.samsung.com/detail/${galaxystoreAppId}`}
            >
              <StoreBanner src={
                require(`!@svgr/webpack!file-loader!~/src/images/galaxystore${isMaybeDesktop && type !== 'small' ? '' : '-mobile'}.svg`).default
              }
              />
            </StoreLink>
          )}
        </>
      )}
    </Container>
  );
};

export default DownloadButtons;
