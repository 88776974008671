import React, { memo } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import $ from 'text2vdom';

import { ImageSharp } from '~/src/generated/types/gatsby';
import { skin, SkinTheme } from '~/src/theme';
import { css, scale } from '~/src/css';
import MobileLineBreak from '~/src/components/MobileLineBreak';
import { CarouselMode } from './types';

interface FeatureCardProps {
  title: string;
  description: string;
  sharp: Partial<ImageSharp>;
  mode: CarouselMode;
  className?: string;
  active?: boolean;
  fold?: boolean;
}

//#region styled-components

const Container = styled('figure')(
  (props: { active: boolean, fold: boolean, mode: CarouselMode }) => css({
    display: 'inline-table',
    boxSizing: 'border-box',
    margin: 0,
    width: props.mode === 'landscape'
      ? [scale(580), scale(580), 800]
      : [scale(500), 420, 440],
    opacity: props.active ? 1 : 0.5,
    transform: props.active ? 'scale(1)' : 'scale(0.9)',
    transition: [
      'opacity 0.3s ease-in-out',
      'transform 0.3s ease-in-out',
    ].join(','),
    border: '1px solid #cbcbcb',
    boxShadow: '0 5px 13px rgba(0, 0, 0, 0.2)',
  })(props),
);

const Caption = styled('figcaption')(
  (props: { fold: boolean, mode: CarouselMode }) => css({
    padding: props.fold ? 0 : [20, 20, '30px'],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: props.fold ? '0' : 'auto',
    transform: props.fold ? 'scaleY(0)' : 'scaleY(1)',
    transition: 'transform, padding',
    transitionDuration: '0.5s',
    willChange: 'transform, padding',
    textAlign: props.mode === 'landscape' ? 'left' : 'center',
  })(props),
);

const Title = styled('h2')(
  css({
    position: 'relative',
    width: '100%',
    margin: 0,
    fontSize: [20, 36, '32px'],
    marginBottom: [10, 10, '9px'],
    fontWeight: 'normal',
    lineHeight: 1,
  }),
);

const TitleText = styled('span')(
  skin({
    blackSquare: (theme: SkinTheme<'blackSquare'>) => css({
      ...theme.underline('&.active'),
      fontWeight: 700,
      transitionDelay: '0.5s',
      '> br': {
        display: 'none',
      },
    }),
  }),
);

const Description = styled('p')(
  css({
    margin: 0,
    width: '100%',
    fontSize: [16, 18, '22px'],
    whiteSpace: 'pre-line',
  }),
);

//#endregion styled-components

const FeatureCard: React.FC<FeatureCardProps> = ({
  title,
  description,
  mode,
  sharp,
  className,
  active = false,
  fold = false,
}) => {
  return (
    <Container
      mode={mode}
      className={className}
      active={active}
      fold={fold}
    >
      <GatsbyImage image={sharp.gatsbyImageData} alt={'feature card'} />
      <Caption mode={mode} fold={fold}>
        <Title>
          <TitleText className={active ? 'active' : void 0}>
            {$(title)}
          </TitleText>
        </Title>
        <Description>
          {$(description, { 'mbr': () => <MobileLineBreak /> })}
        </Description>
      </Caption>
    </Container>
  );
};

export default memo(FeatureCard);
