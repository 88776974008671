import React, { useRef, useEffect, memo, useCallback, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useInView } from 'react-intersection-observer';
import { rem } from 'polished';
import styled from '@emotion/styled';

import { PC_WIDTH } from '~/src/screen';
import { useLazyComponent } from '~/src/misc/ssr';

import ContentBox from '~/src/components/ContentBox';
import SectionBase, { SectionBaseProps } from '../base';
import useCarousel from './useCarousel';
import useSwipeEvent from './useSwipeEvent';
import NativeCarousel from './Carousel.native';
import { skin } from '~/src/theme';
import { css } from '~/src/css';

import { CarouselPlatformVariant, CarouselMode, CarouselItem } from './types';

const MobileCarouselLoader = () => import('./Carousel.mobile');
const PcCarouselLoader = () => import('./Carousel.pc');

export interface CarouselSectionProps extends SectionBaseProps {
  className?: string;
  mode: CarouselMode;
  variant?: CarouselPlatformVariant;
  items: CarouselItem[];
}

const Container = styled(SectionBase)(
  skin({
    blackSquare: css({
      background: 'bg1',
    }),
  }),
);

const CarouselSection: React.FC<CarouselSectionProps> = ({
  id,
  title,
  description,
  mode,
  items,
  variant,
}) => {
  const isPc = useMediaQuery({ minWidth: rem(PC_WIDTH) });
  const isMobile = !isPc;
  const [contentRef, isContentInView] = useInView();

  const [MobileCarousel, loadMobileCarousel] = useLazyComponent(MobileCarouselLoader);
  useEffect(() => {
    if (isContentInView && !MobileCarousel && isMobile) {
      loadMobileCarousel();
    }
  }, [MobileCarousel, isContentInView, isMobile, loadMobileCarousel]);

  const [PcCarousel, loadPcCarousel] = useLazyComponent(PcCarouselLoader);
  useEffect(() => {
    if (isContentInView && !PcCarousel && isPc) {
      loadPcCarousel();
    }
  }, [PcCarousel, isContentInView, isPc, loadPcCarousel]);

  const [inTransition, setInTransition] = useState(false);
  const notInTransition = !inTransition || undefined;
  const {
    carouselItems,
    currentSlideIndex,
    setCurrentSlideIndex,
    goPrev,
    goNext,
    resumeAutoplay,
  } = useCarousel(mode, () => {
    setInTransition(true);
  });
  useEffect(() => {
    if (PcCarousel || MobileCarousel) {
      resumeAutoplay();
    }
  }, [MobileCarousel, PcCarousel, resumeAutoplay]);

  const handleTransitionEnd = useCallback(() => {
    setInTransition(false);
  }, []);

  const swiperableRef = useRef<HTMLDivElement>(null);
  useSwipeEvent(
    swiperableRef,
    direction =>
      (direction === 'right') ? goPrev() :
        (direction === 'left') ? goNext() :
          null,
    50,
  );

  return (
    <Container id={id} title={title} description={description}>
      <ContentBox ref={contentRef}>
        {!(isMobile && MobileCarousel) &&
         !(isPc && PcCarousel) && (
          <NativeCarousel
            key="carousel"
            mode={mode}
            items={carouselItems}
            currentIndex={currentSlideIndex}
          />
        )}
        {PcCarousel && isPc && (
          <PcCarousel
            key="carousel"
            mode={mode}
            items={carouselItems}
            currentIndex={currentSlideIndex}
            setCurrentIndex={notInTransition && setCurrentSlideIndex}
            goPrev={notInTransition && goPrev}
            goNext={notInTransition && goNext}
            onTransitionEnd={handleTransitionEnd}
          />
        )}
        {MobileCarousel && isMobile && (
          <MobileCarousel
            key="carousel"
            ref={swiperableRef}
            mode={mode}
            items={carouselItems}
            currentIndex={currentSlideIndex}
            setCurrentIndex={notInTransition && setCurrentSlideIndex}
            goPrev={notInTransition && goPrev}
            goNext={notInTransition && goNext}
            onTransitionEnd={handleTransitionEnd}
            textSize={
              variant && variant.mobile && variant.mobile.dots && variant.mobile.dots.textSize
            }
          />
        )}
      </ContentBox>
    </Container>
  );
};

export default memo(CarouselSection);
