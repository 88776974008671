import React, { ChangeEventHandler } from 'react';
import { navigate, graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import icon from '@devsisters/ui-common/lib/icon';
import { useLocale } from '@devsisters/gatsby-plugin-i18n';

import { LanguageSelectorQuery } from '~/src/generated/types/gatsby';
import { skin } from '~/src/theme';
import { css } from '~/src/css';
import { useFrontmatter } from '~/src/frontmatter';

const LANGUAGES = {
  ko: '한국어',
  en: 'English',
  ja: '日本語',
  'zh-Hant': '繁體中文',
  'zh-Hans': '简体中文',
  th: 'ภาษาไทย',
} as const;

interface LanguageSelectorProps {
  className?: string;
}

const Container = styled('div')(
  css({
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
  }),
);

const Select = styled('select')(
  css({
    textAlignLast: 'center',
    fontSize: 'inherit',
    color: 'inherit',
    background: 'inherit',
    border: 'none',
    outline: 'none',
    appearance: 'none',
    paddingLeft: [15, 15, 0],
    paddingRight: 30,
    paddingY: 5,
    cursor: 'pointer',
  }),
);

const DownIcon = styled(icon(
  '0 0 69 60',
  <path d="M34.4908 59.8102L0.152588 0.342041H68.829L34.4908 59.8102Z" fill="black" />,
))(
  skin({
    blackSquare: css({
      marginLeft: '0.5em',
      fontSize: '0.5em',
      pointerEvents: 'none',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: 10,
    }),
  }),
);

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ className }) => {
  const data = useStaticQuery<LanguageSelectorQuery>(graphql`
    query LanguageSelector {
      allSitePage {
        nodes {
          path
        }
      }
    }
  `);


  const { gameId } = useFrontmatter();
  // FIXME: 페이지 컨텍스트 YAML 기반으로 갈아 엎을 것, 거기에 파일 이름이 있다고 가정
  const currentLocale = useLocale();
  const locales = data.allSitePage.nodes
    .filter(node => node.path.endsWith(`${gameId}/`))
    .map(node => node.path)
    .map(path => path.split('/')[1] as keyof typeof LANGUAGES)
    .slice(1);

  const handleChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    const locale = e.currentTarget.value;
    navigate(`/${locale}/${gameId}/`, { replace: true });
  };

  return (
    <Container className={className}>
      <Select
        defaultValue={currentLocale}
        onChange={handleChange}
        className='language-selector'
      >
        {locales.map(locale => (
          <option
            key={locale}
            value={locale}
          >
            {LANGUAGES[locale]}
          </option>
        ))}
      </Select>
      <DownIcon />
    </Container>
  );
};

export default LanguageSelector;
