import React, { useState, useEffect, useContext, createContext } from 'react';

interface WatchingSectionState {
  watchingSection: HTMLElement | null;
  visibleSections: HTMLElement[];
}

type Props = {
  children: React.ReactElement[];
  location: Location;
};

// @ts-ignore
export const watchingSectionContext = createContext<WatchingSectionState>(null);
export const WatchingSectionProvider: React.FC<Props> = ({ children, location }: Props) => {
  const [watchingSectionState, setWatchingSectionState] = useState<WatchingSectionState>({
    watchingSection: null,
    visibleSections: [],
  });
  useEffect(() => {
    const allSections: HTMLElement[] = Array.from(document.querySelectorAll('#___gatsby > div > section'));
    const update = () => {
      const centerY = window.scrollY + (window.innerHeight / 2);
      const visibleSections = allSections.filter(a => getComputedStyle(a).getPropertyValue('display') !== 'none');
      const firstSection = visibleSections[0];
      const lastSection = visibleSections[visibleSections.length - 1];
      if (
        (!visibleSections.length) ||
        (centerY < firstSection.offsetTop) ||
        (centerY > (lastSection.offsetTop + lastSection.offsetHeight))
      ) return setWatchingSectionState({ watchingSection: null, visibleSections });
      const offsets = visibleSections.map(section => (section.offsetTop + section.offsetHeight));
      const index = bisect(offsets, centerY);
      setWatchingSectionState({ watchingSection: visibleSections[index], visibleSections });
    };
    update();
    window.addEventListener('scroll', update);
    window.addEventListener('resize', update);
    return () => {
      window.removeEventListener('scroll', update);
      window.removeEventListener('resize', update);
    };
  }, [location.pathname]);
  return (
    <watchingSectionContext.Provider value={watchingSectionState}>
      {children}
    </watchingSectionContext.Provider>
  );
};

export default function useWatchingSection(): WatchingSectionState {
  return useContext(watchingSectionContext);
}

function bisect(
  a: number[],
  x: number,
  lo: number = 0,
  hi: number = a.length,
) {
  while (lo < hi) {
    const mid = (lo + hi) >> 1;
    if (x < a[mid]) hi = mid;
    else lo = mid + 1;
  }
  return lo;
}
