import React, { useEffect, useMemo } from 'react';

import { useFrontmatter, SectionType, SectionItem } from '~/src/frontmatter';
import useWatchingSection from '~/src/misc/useWatchingSection';

import BackgroundImage from './BackgroundImage';
import HotIssue from './HotIssue';
import Carousel from './Carousel';

const sectionMap = {
  'hot-issue': HotIssue,
  'carousel': Carousel,
  'background-image': BackgroundImage,
} as const;

const Sections: React.FC = () => {
  const frontmatter = useFrontmatter();
  const sections = frontmatter.sections as unknown as SectionItem[];
  const SectionList = useMemo(() => (
    sections
      .map(sectionItem => {
        const sectionType = sectionItem.type as SectionType;
        const Section = sectionMap[sectionType];
        return [Section, sectionItem] as const;
      })
      .map(([Section, sectionItem]) => (
        // @ts-ignore
        <Section key={sectionItem.id} {...sectionItem}/>
      ))
  ), [sections]);

  const { watchingSection, visibleSections } = useWatchingSection();

  useEffect(function useActivationEffect() {
    for (const section of visibleSections) {
      const active = section.classList.contains('active');
      if (active && (section !== watchingSection)) section.classList.remove('active');
      else if (!active && (section === watchingSection)) section.classList.add('active');
    }
  }, [watchingSection, visibleSections]);

  useEffect(function useChangeHashEffect() {
    if (!watchingSection || !watchingSection.id) return;
    window.history.replaceState(null, '', `#${watchingSection.id}`);
  }, [watchingSection]);

  return <>{SectionList}</>;
};

export default Sections;
