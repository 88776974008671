import React from 'react';
import styled from '@emotion/styled';
import { rem } from 'polished';

import { css } from '~/src/css';
import { skin } from '~/src/theme';
import { useFrontmatter, SocialLink } from '~/src/frontmatter';

import _ContentBox from '~/src/components/ContentBox';
import SocialLinks from '~/src/components/SocialLinks';
import _Banner from '~/src/components/Banner';
import _Button from '~/src/components/Button';
import LanguageSelector from '~/src/components/LanguageSelector';

interface MobileNavigationProps {
  testId: string;
  children?: React.ReactNode;
}

const Container = styled('div')();

const ContentBox = styled(_ContentBox)(
  skin({
    blackSquare: css({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingY: 61,
      minHeight: `calc(100vh - ${rem(61*2)})`,
    }),
  }),
);

const Wrapper = styled('div')(
  skin({
    blackSquare: css({
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }),
  }),
);

const Banner = styled(_Banner)(
  skin({
    blackSquare: css({
      width: '100%',
      marginBottom: 30,
    }),
  }),
);

const Button = styled(_Button)(
  skin({
    blackSquare: css({
      fontSize: 18,
      marginX: 15,
      height: 37,
      overflow: 'auto',
    }),
  }),
);

const ButtonLangSelector = Button.withComponent(LanguageSelector);

const MobileNavigation: React.FC<MobileNavigationProps> = ({
  testId,
  children,
}) => {
  const frontmatter = useFrontmatter();
  const banner = frontmatter.navigation.banner;
  const socials = frontmatter.navigation.socials as unknown as SocialLink[] | undefined;
  return (
    <Container data-testid={testId}>
      <ContentBox data-scroll-lock-ignore>
        <Wrapper>
          <Banner
            fluid
            loading="lazy"
            css={css({
              height: [banner.mobile.h, 100],
            })}/>
          {children}
        </Wrapper>
        <Wrapper css={css({
          marginBottom: 40,
        })}>
          {socials && (
            <div css={css({
              fontSize: [60 - (4 * Math.max(socials.length, 3)), 54, 54],
              marginBottom: 30,
            })}>
              <SocialLinks socials={socials}/>
            </div>
          )}
          <div css={css({
            display: 'inline-flex',
            alignItems: 'center',
          })}>
            <ButtonLangSelector/>
          </div>
        </Wrapper>
      </ContentBox>
    </Container>
  );
};

export default MobileNavigation;
