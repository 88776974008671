import React from 'react';

import { MdxFrontmatter } from '~/src/generated/types/gatsby';

import { SectionBaseProps } from '~/src/components/Sections/base';
import { HotIssueSectionProps } from '~/src/components/Sections/HotIssue';
import { BackgroundImageSectionProps } from '~/src/components/Sections/BackgroundImage';
import { CarouselSectionProps } from '~/src/components/Sections/Carousel';

export type Frontmatter = MdxFrontmatter;

type SectionItemProps<T extends string, P extends Omit<SectionBaseProps, 'className'>> =
  { type: T } & Omit<P, 'className'>;

export type SectionItem =
  | SectionItemProps<'hot-issue', HotIssueSectionProps>
  | SectionItemProps<'background-image', BackgroundImageSectionProps>
  | SectionItemProps<'carousel', CarouselSectionProps>;

export type SectionType = SectionItem['type'];

export type SocialType = (
  | 'forum'
  | 'youtube'
  | 'facebook'
  | 'twitter'
  | 'navercafe'
  | 'instagram'
  | 'reddit'
  | 'discord'
  | 'weibo'
  | 'bilibili'
  | 'tiktok'
);

export type SocialLink = {
  type: SocialType,
  url: string,
};

// @ts-ignore
export const frontmatterContext = React.createContext<Frontmatter>({});

export function useFrontmatter(): Frontmatter {
  return React.useContext(frontmatterContext);
}
