import React from 'react';
import { Helmet } from 'react-helmet';

import { useFrontmatter } from '~/src/frontmatter';

const SiteMetadata: React.FC = () => {
  const { locale, title, description } = useFrontmatter();

  return (
    <Helmet>
      <html lang={locale}/>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default SiteMetadata;
