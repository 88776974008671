import { rem, rgba } from 'polished';
import fromEntries from 'lodash/fromPairs';
import { Parser } from '@styled-system/core';
import { useTheme as emotionUseTheme } from '@emotion/react';
import css, {
  Theme as _Theme,
  SystemStyleObject,
  CSSObject,
} from '@styled-system/css';

import { MOBILE_WIDTH, PC_WIDTH } from '~/src/screen';

export const breakpoints = [
  rem(MOBILE_WIDTH),
  rem(PC_WIDTH),
];

export const skins = {
  unstyled: undefined,
  blackSquare: {
    primary: {
      color: '#fff',
      background: '#191d24',
    },
    secondary: {
      color: '#ffd71e',
    },
    normal: {
      color: '#aeaeb3',
    },
    title: {
      color: '#000',
      // FIXME: [WSG-328] 스킨시스템에 rem 기반, scaled 테마 적용되도록 수정
    },
    bg1: {
      background: '#fff',
      bg: '#fff',
    },
    bg2: {
      background: '#f2f2f2',
      bg: '#f2f2f2',
    },
    hamburger: {
      zIndex: 4,
    },
    dim: {
      zIndex: 3,
      background: rgba('#191d24', 0.95),
    },
    navbar: {
      zIndex: 2,
      color: '#000',
      fontWeight: 400,
      background: '#fff',
      height: rem(80),
    },
    section: {
      zIndex: 1,
    },
    buttons: {
      primary: {
        color: 'primary',
        background: 'primary',
        border: '1px solid #fff',
      },
    },
    badge: {
      color: '#191d24',
      background: '#ffd71e',
    },
    underline: (activeSelector?: string) => ({
      position: 'relative',
      backgroundImage: 'linear-gradient(transparent 60%, #ffd71e 40%)',
      backgroundRepeat: 'no-repeat',
      '-webkit-box-decoration-break': 'clone',
      ...(activeSelector && {
        backgroundSize: '0% 100%',
        transition: 'background-size 0.3s ease-in-out 0s',
        [activeSelector]: {
          backgroundSize: '100% 100%',
        },
      }),
    }) as const,
  },
} as const;

export type Skin = keyof typeof skins;
export type SkinTheme<T extends Skin> = typeof skins[T];
export const defaultSkin: Skin = 'unstyled';

export type Theme = _Theme & {
  skin?: Skin,
  skins: { [key in Skin]: SystemStyleObject | undefined },
};
export const defaultTheme: Theme = {
  breakpoints,
  skin: undefined,
  skins,
};

type SkinProps = {
  [key in Skin]?: Parser | ((theme: SkinTheme<key>) => Parser)
};

export const skin = (input: SkinProps): Parser => props => {
  const theme = {
    ...defaultTheme,
    ...props.theme,
  };
  const skin = theme.skin || defaultSkin;
  const skinTheme = theme.skins[skin] || {};
  const systemStyle = input[skin] as unknown;
  const cssObj = css(
    typeof systemStyle === 'function' ? systemStyle(skinTheme) : systemStyle,
  )(props);

  const convertCss = ([key, value]: [string, any]) => {
    let resultValue = value;
    if (Object.keys(skinTheme).includes(value)) {
      // FIXME: [WSG-318] theme 유틸리티 구현 내 타입 에러 수정할 것
      // @ts-ignore
      resultValue = skinTheme[value][key];
    }
    return [key, resultValue];
  };

  const transform = (obj: CSSObject): CSSObject => fromEntries(
    Object.entries(obj)
      .map(([key, value]) => [
        key,
        typeof value === 'object' ? transform(value) : value,
      ])
      // FIXME
      // @ts-ignore
      .map(convertCss),
  );

  return transform(cssObj);
};

export const createTheme = (theme: Partial<Theme>) => ({
  ...defaultTheme,
  ...theme,
});

export const useTheme = () => emotionUseTheme() as Theme;
