import React from 'react';
import { rem } from 'polished';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import { useLocale } from '@devsisters/gatsby-plugin-i18n';

import { KeyVisualQuery } from '~/src/generated/types/gatsby';

import { PC_SCREEN_WIDTH, MOBILE_SCREEN_WIDTH } from '~/src/screen';
import { css, scaledCss } from '~/src/css';
import { useFrontmatter } from '~/src/frontmatter';

import DownloadButtons from '~/src/components/DownloadButtons';
import { convertToBgImage } from 'gbimage-bridge';

interface BackgroundImageProps {
  minHeight: number;
}

const PcBackgroundImage = styled(BackgroundImage)(
  (props: BackgroundImageProps) => {
    const size = props.minHeight;
    return css({
      display: ['none', 'block'],
      minHeight: `${size}px`,
      height: `${size / PC_SCREEN_WIDTH * 100}vw`,
    })(props);
  },
);

const MobileBackgroundImage = styled(BackgroundImage)(
  (props: BackgroundImageProps) => {
    const size = props.minHeight;
    return css({
      display: ['block', 'none'],
      minHeight: `${size}px`,
      height: `${size / MOBILE_SCREEN_WIDTH * 100}vw`,
    })(props);
  },
);

const Image = styled(GatsbyImage, {})(
  css({
    width: '100%',
    height: '100%',
  }),
);

const KeyVisual: React.FC = () => {
  const frontmatter = useFrontmatter();
  const locale = useLocale();

  const data = useStaticQuery<KeyVisualQuery>(graphql`
    query KeyVisual {
      allStrapiGamesiteMain {
        nodes {
          locale
          environment
          game
          logo {
            localFile{
              childImageSharp {
                gatsbyImageData(
                  quality: 100,
                  width: 500,
                  breakpoints: [360, 640, 1200],
                  formats: [AUTO, WEBP],
                )
              }
            }
          }
          mainImageMobile {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 90,
                  width: 360,
                  breakpoints: [360, 640],
                  formats: [AUTO, WEBP],
                )
              }
            }
          }
          mainImagePc {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 90,
                  width: 1920,
                  breakpoints: [640, 1200],
                  formats: [AUTO, WEBP],
                )
              }
            }
          }
        }
      }
    }
  `);

  const { logo } = frontmatter.top;
  const gameNodes = data.allStrapiGamesiteMain.nodes
    ?.filter(node => (node.environment === (process.env.GATSBY_ENVIRONMENT ?? 'preview')) && node.game === frontmatter.gameId);
  const gameNode = gameNodes?.find((node) => node.locale.toLowerCase() === locale.toLowerCase());
  const logoNode = gameNode?.logo.localFile;
  const mainImagePc = gameNode?.mainImagePc;
  const mainImageMobile = gameNode?.mainImageMobile;
  return (
    <>
      {mainImagePc && (
        <PcBackgroundImage
          Tag="section"
          {...convertToBgImage(getImage(mainImagePc.localFile))}
          minHeight={mainImagePc.localFile.childImageSharp.gatsbyImageData.height}
        >
          <div
            css={css({
              display: 'inline-flex',
              flexDirection: 'column',
              alignItems: 'center',
              position: 'absolute',
              bottom: 40,
              left: '50%',
              transform: 'translateX(-50%)',
            })}
          >
            <div
              css={css({
                width: [logo.pc.w * 0.8, logo.pc.w * 0.8, logo.pc.w * 0.8, logo.pc.w],
                height: [logo.pc.h * 0.8, logo.pc.h * 0.8, logo.pc.h * 0.8, logo.pc.h],
                ...logo.pc.margin && { marginBottom: logo.pc.margin },
              })}
            >
              <Image
                alt="logo"
                loading="eager"
                objectFit="contain"
                image={logoNode.childImageSharp.gatsbyImageData}
              />
            </div>
            <DownloadButtons
              type='large'
              css={css({
                width: ['100vw', 700, 700, '100vw'],
                '> a': {
                  width: 230,
                  boxShadow: 'none',
                  borderRadius: 0,
                  marginTop: 10,
                  ':not(:first-of-type)': {
                    marginLeft: 10,
                  },
                },
                '> a > img': {
                  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
                  borderRadius: 80,
                  width: '100%',
                  height: 'auto',
                },
              })}
            />
          </div>
        </PcBackgroundImage>
      )}
      {mainImageMobile && (
        <MobileBackgroundImage
          Tag="section"
          {...convertToBgImage(getImage(mainImageMobile.localFile))}
          minHeight={mainImageMobile.localFile.childImageSharp.gatsbyImageData.height}
        >
          <div
            css={scaledCss({
              display: 'inline-flex',
              flexDirection: 'column',
              alignItems: 'center',
              position: 'absolute',
              top: logo.mobile.y,
              left: '50%',
              transform: 'translateX(-50%)',
              width: '100%',
            })}
          >
            <div
              css={scaledCss({
                width: logo.mobile.w,
                height: logo.mobile.h,
                ...logo.mobile.margin && { marginBottom: logo.mobile.margin },
              })}
            >
              <Image
                alt="logo"
                loading="eager"
                objectFit="contain"
                image={logoNode.childImageSharp.gatsbyImageData}
              />
            </div>
            <DownloadButtons
              type='small'
              css={css({
                paddingY: rem(5),
                width: '100%',
                maxWidth: 340,
                '> a': {
                  width: `calc(50% - ${rem(4)})`,
                  height: 'auto',
                  marginTop: rem(5),
                  '@media (min-width: 359px)': {
                    width: 156,
                    height: 50,
                    marginTop: rem(10),
                  },
                  ':not(:nth-of-type(odd))': {
                    marginLeft: [8, 0],
                  },
                },
              })}
            />
          </div>
        </MobileBackgroundImage>
      )}
    </>
  );
};

export default KeyVisual;
