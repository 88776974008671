import React from 'react';
import styled from '@emotion/styled';

import { css } from '~/src/css';
import { skin } from '~/src/theme';
import { SocialType, SocialLink } from '~/src/frontmatter';
import Link from '~/src/components/Link';

import { ReactComponent as YoutubeIconSvg } from '~/src/images/social/youtube.svg';
import { ReactComponent as FacebookIconSvg } from '~/src/images/social/facebook.svg';
import { ReactComponent as TwitterIconSvg } from '~/src/images/social/twitter.svg';
import { ReactComponent as RedditIconSvg } from '~/src/images/social/reddit.svg';
import { ReactComponent as ForumIconSvg } from '~/src/images/social/forum.svg';
import { ReactComponent as NaverCafeIconSvg } from '~/src/images/social/naver-cafe.svg';
import { ReactComponent as InstagramIconSvg } from '~/src/images/social/instagram.svg';
import { ReactComponent as DiscordIconSvg } from '~/src/images/social/discord.svg';
import { ReactComponent as WeiboIconSvg } from '~/src/images/social/weibo.svg';
import { ReactComponent as BiliBiliIconSvg } from '~/src/images/social/bilibili.svg';
import { ReactComponent as TiktokIconSvg } from '~/src/images/social/tiktok.svg';

interface SocialLinksProps {
  className?: string;
  socials: SocialLink[];
}

const Container = styled('div')(
  skin({
    blackSquare: css({
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      width: ['100%', 550],
      height: '100%',
      margin: '0 auto',
    }),
  }),
);

const IconLink = styled(Link)(
  skin({
    blackSquare: css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginX: ['0.12em', '0.2em'],
      marginBottom: '0.3em',
      width: ['1.1em', '1.2em'],
      height: ['1.1em', '1.2em'],
      '> svg': {
        width: '100%',
        overflow: 'visible',
      },
    }),
  }),
);

const SocialLinks: React.FC<SocialLinksProps> = ({
  className,
  socials,
}) => {
  return (
    <Container className={className}>
      {socials.map(social => {
        const SocialIconSvg = iconMap[social.type];
        return (
          <IconLink key={social.type} to={social.url} className='social-link'>
            <SocialIconSvg />
          </IconLink>
        );
      })}
    </Container>
  );
};

export default SocialLinks;

const iconMap: { [key in SocialType]: React.FC } = {
  youtube: YoutubeIconSvg,
  facebook: FacebookIconSvg,
  twitter: TwitterIconSvg,
  instagram: InstagramIconSvg,
  reddit: RedditIconSvg,
  forum: ForumIconSvg,
  navercafe: NaverCafeIconSvg,
  discord: DiscordIconSvg,
  weibo: WeiboIconSvg,
  bilibili: BiliBiliIconSvg,
  tiktok: TiktokIconSvg,
};
