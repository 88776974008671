import React from 'react';
import { Helmet } from 'react-helmet';

import { useFrontmatter } from '~/src/frontmatter';
import { useSiteMetadata } from '~/src/data/siteMetadata';

interface OpenGraphProps {
  location: Location;
}

const OpenGraph: React.FC<OpenGraphProps> = ({ location }) => {
  const { siteUrl } = useSiteMetadata();
  const { image, title, description, gameId } = useFrontmatter();
  return (
    <Helmet>
      <html prefix="og: http://ogp.me/ns#" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`${siteUrl + location.pathname}`} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={siteUrl + require('!!file-loader!~/src/images/' + image).default} />
      {gameId === 'cookierunpuzzle' && (
        <meta property="fb:app_id" content="309237986325207" />
      )}
      <link rel="shortcut icon" href={`/favicon/${gameId}.png`} />
      <link rel="apple-touch-icon" href={`/favicon/${gameId}.png`} />
      <link rel="icon" href={`/favicon/${gameId}.png`} />
    </Helmet>
  );
};

export default OpenGraph;
