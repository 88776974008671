import React, { memo, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { icon } from '@devsisters/ui-common';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
// @ts-ignore
import Div100vh from 'react-div-100vh';

import { css, scaledCss } from '~/src/css';
import { skin } from '~/src/theme';
import ContentBox from '~/src/components/ContentBox';
import BodyPortal from '~/src/components/BodyPortal';

type HideDirection = 'left' | 'right';
type CloseButtonPosition = HideDirection;
interface PopupProps {
  id?: string;
  className?: string;
  style?: React.CSSProperties;
  onCloseButtonClick?: () => void;
  hide?: boolean;
  hideDirection?: HideDirection;
  children?: React.ReactElement;
}

const DimmedPopup: React.FC<PopupProps> = ({
  id,
  className,
  style,
  children,
  onCloseButtonClick,
  hide,
  hideDirection = 'left',
}) => {
  const domRef = useRef<HTMLDivElement>(null);
  // useEffect(() => {
  //   const dom = domRef.current;
  //   if (!dom) return;

  //   if (hide) {
  //     enableBodyScroll(dom);
  //   } else {
  //     disableBodyScroll(dom, {
  //       allowTouchMove: el => {
  //         while (el && el !== document.body) {
  //           if (el.getAttribute('data-scroll-lock-ignore') !== null) {
  //             return true;
  //           }
  //           el = el.parentElement!;
  //         }
  //       },
  //     });
  //   }

  //   return () => enableBodyScroll(dom);
  // }, [hide]);
  return (
    <BodyPortal ref={domRef}>
      <DimmedPopupContainer
        id={id}
        style={style}
        className={className}
        $hide={!!hide}
        $hideDirection={hideDirection}
      >
        <Dim />
        <Contents>
          <ContentBox css={{
            position: 'relative',
            height: '100%',
          }}>
            {(hideDirection === 'right') && (
              <CloseButton
                onClick={onCloseButtonClick}
                position={hideDirection}
                data-testid="close-popup-button"
              >
                <CloseIcon />
              </CloseButton>
            )}
            {children}
          </ContentBox>
        </Contents>
      </DimmedPopupContainer>
    </BodyPortal>
  );
};

export default memo(DimmedPopup);

interface DimmedPopupContainerProps {
  $hide: boolean;
  $hideDirection: HideDirection;
}
const DimmedPopupContainer = styled(Div100vh, { shouldForwardProp: (name) => !name.startsWith('$') })(
  ({ $hide, $hideDirection }: DimmedPopupContainerProps) => css({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100vw',
    maxHeight: '100vh',
    transform:
      $hide ?
        ($hideDirection === 'left' ? 'translateX(-100%)' : 'translateX(100%)') :
        'none',
    pointerEvents: $hide ? 'none' : undefined,
    transition: 'transform 0.3s',
  }),
  skin({
    // @ts-ignore
    blackSquare: css({
      zIndex: 'dim',
    }),
  }),
);

const Dim = styled('div')(
  css({
    position: 'absolute',
    width: '100%',
    height: '100vh',
    minHeight: '100vh',
  }),
  skin({
    blackSquare: css({
      background: 'dim',
    }),
  }),
);

const Contents = styled('div')(
  css({
    position: 'absolute',
    width: '100%',
    height: '100vh',
    minHeight: '100vh',
    overflowX: 'hidden',
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
  }),
  skin({
    blackSquare: css({
      color: '#fff',
    }),
  }),
);

const CloseIcon = styled(icon(
  '0 0 46 47',
  <path d="M27.950,23.500 L44.920,40.471 C46.287,41.837 46.287,44.054 44.920,45.421 C43.553,46.787 41.337,46.787 39.971,45.421 L23.000,28.450 L6.029,45.421 C4.663,46.787 2.447,46.787 1.080,45.421 C-0.287,44.054 -0.287,41.837 1.080,40.471 L18.050,23.500 L1.080,6.529 C-0.287,5.163 -0.287,2.947 1.080,1.580 C2.447,0.213 4.663,0.213 6.029,1.580 L23.000,18.550 L39.971,1.580 C41.337,0.213 43.553,0.213 44.920,1.580 C46.287,2.947 46.287,5.163 44.920,6.529 L27.950,23.500 Z" />,
))(
  skin({
    blackSquare: scaledCss({
      fontSize: [47, '46px'],
    }),
  }),
);
interface CloseButtonProps {
  position: CloseButtonPosition;
}
const CloseButton = styled('button')(
  (_) => scaledCss({
    position: 'absolute',
    top: [30, '30px'],
    cursor: 'pointer',
  }),
  ({ position }: CloseButtonProps) => scaledCss(
    position === 'left' ?
      { left: [30, 30, '30px'] } :
      { right: [30, 30, '30px'] },
  ),
  skin({
    blackSquare: css({
      padding: 0,
      color: '#fff',
      background: 'none',
      border: 'none',
      outline: 'none',
    }),
  }),
);
