import React, { useMemo } from 'react';
import fromEntries from 'lodash/fromPairs';
import { graphql, useStaticQuery } from 'gatsby';
import { Global, ThemeProvider } from '@emotion/react';
import { i18nContext } from '@devsisters/gatsby-plugin-i18n';
import { LocalizationData, LocalizedResources, Translations } from '@devsisters/gatsby-plugin-i18n/types';

import { createTheme, Skin } from '~/src/theme';
import { frontmatterContext, Frontmatter } from '~/src/frontmatter';
import { WatchingSectionProvider } from '~/src/misc/useWatchingSection';
import { PlatformProvider } from '~/src/misc/usePlatform';
import { useEasterEgg } from '~/src/misc/useEasterEgg';

import SiteMetadata from '~/src/components/SiteMetadata';
import OpenGraph from '~/src/components/OpenGraph';
import TwitterCard from '~/src/components/TwitterCard';
import { Interpolation } from '@emotion/styled';

export interface PageContext {
  frontmatter: Frontmatter;
}

export interface LayoutProps {
  children: React.ReactElement;
  location: Location;
  pageContext: PageContext;
}

const Layout: React.FC<LayoutProps> = ({ children, location, pageContext }) => {
  const locale = pageContext.frontmatter.locale;
  const translations = useTranslations();
  const i18nContextValue = useMemo(() => ({ locale, translations }), [locale, translations]);
  const theme = useTheme(pageContext.frontmatter.skin as Skin);
  useEasterEgg(['Join us 👉 https://careers.devsisters.com/']);

  React.useEffect(() => {
    const href = location.href;
    if (href.includes('ko/cookieruntoa')) {
      window.location.replace('https://cookierun-towerofadventures.com/ko/');
    } else if (href.includes('en/cookieruntoa')) {
      window.location.replace('https://cookierun-towerofadventures.com/en/');
    } else if (href.includes('th/cookieruntoa')) {
      window.location.replace('https://cookierun-towerofadventures.com/th/');
    } else if (href.includes('zh-Hant/cookieruntoa')) {
      window.location.replace('https://cookierun-towerofadventures.com/zh-Hant/');
    }
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <i18nContext.Provider value={i18nContextValue}>
        <frontmatterContext.Provider value={pageContext.frontmatter}>
          <PlatformProvider>
            <WatchingSectionProvider location={location}>
              <SiteMetadata/>
              <OpenGraph location={location}/>
              <TwitterCard location={location}/>
              <Global styles={globalStyles}/>
              {children}
            </WatchingSectionProvider>
          </PlatformProvider>
        </frontmatterContext.Provider>
      </i18nContext.Provider>
    </ThemeProvider>
  );
};

export default Layout;

const globalStyles: Interpolation<any> = {
  body: {
    margin: 0,
    wordBreak: 'break-word',
    'html[lang=ko] > body': {
      wordBreak: 'keep-all',
    },
  },
  '#___gatsby': {
    // See [WI-19]
    // ~구 버전 크로미엄(네이버, 삼성 인터넷 브라우저 해당)의 왠지 모를 버그 대응~
    // 크롬 버전 문제라기에는 그냥 화면 많이 좁혔을 때도 발생하네
    //
    // html 엘리먼트 건드리는 순간 body-scroll-lock 이 동작 안해서 gatsby wrapper 쪽으로 셀렉터 변경
    overflowX: 'hidden',
  },
};

type CreateLayout = (LayoutComponent: React.ComponentType) => React.FC<LayoutProps>;
export const createLayout: CreateLayout = (LayoutComponent) => ({ children, location, pageContext }) => {
  if (!pageContext.frontmatter) {
    /**
     * frontmatter가 주입되지 않은 (mdx가 아닌) 페이지는 frontmatter를 사용하는 layout이 적용되지 않는다.
     * (useLocaleRedirect 등이 작성되어있는 자체 페이지만 동작함)
     * ex) /, /cookierun/, /ko/ 등
     */
    return (
      <>{children}</>
    );
  }
  return (
    <Layout location={location} pageContext={pageContext}>
      <LayoutComponent/>
    </Layout>
  );
};

function useTranslations() {
  const data = useStaticQuery(graphql`
    query Layout {
      allLocalization {
        nodes {
          locale
          translations {
            key
            value
          }
        }
      }
    }
  `);
  const l10ns: LocalizationData[] = data.allLocalization.nodes;
  const translations = useMemo(() => fromEntries(
    l10ns.map(({ locale, translations }) => [
      locale,
      fromEntries(
        translations.map(({ key, value }) => [key, value]),
      ) as LocalizedResources,
    ]),
  ) as Translations, [l10ns]);
  return translations;
}

function useTheme(skin: Skin) {
  return useMemo(() => createTheme({ skin }), [skin]);
}
