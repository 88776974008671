import React from 'react';
import styled from '@emotion/styled';

import { skin } from '~/src/theme';
import { css, scale } from '~/src/css';

import _ContentBox from '~/src/components/ContentBox';

interface NavigationBarProps {
  rightChildren?: React.ReactElement;
  className?: string;
  transparent?: boolean;
}

const Container = styled('div')(
  skin({
    blackSquare: css({
      display: 'flex',
      alignItems: 'center',
      background: 'dim',
      width: '100%',
      height: [scale(60), 50],
      paddingY: 16,
    }),
  }),
);

const ContentBox = styled(_ContentBox)(
  skin({
    blackSquare: css({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
    }),
  }),
);

const MenuWrapper = styled('div')(
  skin({
    blackSquare: css({
      display: 'inline-flex',
      alignItems: 'center',
    }),
  }),
);

const NavigationBar: React.FC<NavigationBarProps> = ({
  rightChildren,
  className,
  transparent = false,
}) => {
  return (
    <Container
      className={className}
      css={transparent
        ? { background: 'transparent' }
        : undefined
      }
    >
      <ContentBox>
        <MenuWrapper/>
        <MenuWrapper>
          {rightChildren}
        </MenuWrapper>
      </ContentBox>
    </Container>
  );
};

export default NavigationBar;
