import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useInView } from 'react-intersection-observer';
import { rem } from 'polished';
import styled from '@emotion/styled';

import { css, scale, scaledCss } from '~/src/css';
import { skin } from '~/src/theme';
import { PC_WIDTH } from '~/src/screen';
import { useFrontmatter } from '~/src/frontmatter';
import usePlatform from '~/src/misc/usePlatform';

import { ReactComponent as _DevsistersGamesIcon } from '~/src/images/devsisters-games.svg';
import DownloadButtons from '~/src/components/DownloadButtons';
import DownloadButton from '~/src/components/DownloadButton';
import BodyPortal from '~/src/components/BodyPortal';
import DimmedPopup from '~/src/components/DimmedPopup';
import DevsistersGames from '~/src/components/DevsistersGames';
import Hamburger from '~/src/components/Hamburger';

import CommonNavigation from './CommonNavigation';
import NavigationMenu from './NavigationMenu';
import PcNavigationBar from './NavigationBar.pc';
import _MobileNavigationBar from './NavigationBar.mobile';
import MobileNavigation from './MobileNavigation';
import { useOtherGames } from '~/src/games';

const PcContainer = styled('header')(
  props => skin({
    // @ts-ignore
    blackSquare: css({
      zIndex: 'navbar',
      display: ['none', 'none', 'block'],
    })(props),
  }),
);

const MobileContainer = styled('header')(
  props => skin({
    // @ts-ignore
    blackSquare: css({
      position: 'absolute',
      width: '100%',
      zIndex: 'navbar',
      display: ['block', 'block', 'none'],
    })(props),
  }),
);

type HidingContainerProps = {
  hide: boolean,
};

const PcHidingContainer = styled(PcContainer)(
  (props: HidingContainerProps) => css({
    position: 'fixed',
    width: '100%',
    top: 0,
    transition: 'transform 0.3s ease-in-out',
    display: ['none', 'none', 'block'],
    boxShadow: '0px 1px 8px 4px #8b8b8b3b',
    transform: props.hide
      ? 'translateY(-100%)'
      : 'translateY(0)',
  })(props),
);

const MobileHidingContainer = styled(MobileContainer)(
  (props: HidingContainerProps) => css({
    position: 'fixed',
    width: '100%',
    height: 'auto',
    top: -1,
    transition: 'transform 0.3s ease-in-out',
    display: ['block', 'block', 'none'],
    transform: props.hide
      ? 'translateY(-100%)'
      : 'translateY(0)',
  })(props),
);


const MobileNavigationBar = styled(_MobileNavigationBar)(
  css({
    display: ['block', 'block', 'none'],
    paddingY: 16,
  }),
);

const DevsistersGamesButton = styled('button')(
  css({
    background: 'none',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    padding: 0,
    paddingRight: [8, 0],
  }),
);

const DevsistersGamesIcon = styled(_DevsistersGamesIcon)(
  css({
    color: '#fff',
    width: [150, 216, '125px'],
    height: 'auto',
    marginTop: 4,
    '> *': {
      fill: 'currentColor',
    },
  }),
);

const Header: React.FC = () => {
  const otherGames = useOtherGames();
  const hasOtherGames = otherGames.length >= 1;

  const [pcRef, pcTopNavbarInView] = useInView();
  const [attachPcHiding, setAttachPcHiding] = useState(false);
  useEffect(() => {
    if (pcTopNavbarInView) {
      setAttachPcHiding(true);
    }
  }, [pcTopNavbarInView]);

  const [mobileRef, mobileTopNavbarInView] = useInView();
  const [attachMobileHiding, setAttachMobileHiding] = useState(false);
  useEffect(() => {
    if (mobileTopNavbarInView) {
      setAttachMobileHiding(true);
    }
  }, [mobileTopNavbarInView]);

  const [openNav, setOpenNav] = useState(false);
  const toggleNav = () => setOpenNav(!openNav);
  const closeNav = () => setOpenNav(false);

  const [openGames, setOpenGames] = useState(false);
  const toggleGames = () => setOpenGames(!openGames);
  const closeGames = () => setOpenGames(false);

  const isPc = useMediaQuery({ minWidth: rem(PC_WIDTH) });
  useEffect(() => {
    closeNav();
    setOpenGames(false);
  }, [isPc]);

  const platform = usePlatform();
  const isAndroid = platform === 'android';
  const { onestoreAppId, galaxystoreAppId } = useFrontmatter();
  const hasPlatformDetected = platform !== 'unknown';
  const collapseDownloads = Boolean(
    (!hasPlatformDetected && onestoreAppId) ||
    (isAndroid && onestoreAppId && galaxystoreAppId),
  );

  return (
    <>
      <PcContainer
        ref={pcRef}
        data-testid="pc-header-top"
      >
        <CommonNavigation key={pcTopNavbarInView.toString()} />
        <PcNavigationBar
          rightChildren={<NavigationMenu variant="pc" />}
        />
      </PcContainer>

      {attachPcHiding && (
        <PcHidingContainer hide={pcTopNavbarInView}>
          <PcNavigationBar
            leftChildren={<NavigationMenu variant="pc" />}
            rightChildren={collapseDownloads ? (
              <DownloadButton />
            ) : (
              <div css={{ height: '100%' }}>
                <DownloadButtons
                  css={css({
                    height: '100%',
                    alignItems: 'center',
                    '> a': {
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: 114,
                      height: 40,
                      boxSizing: 'border-box',
                      boxShadow: 'none',
                      '@media (min-width: 640px)': {
                        width: 130,
                        height: 54,
                      },
                      '@media (min-width: 1200px)': {
                        width: 144,
                        height: 50,
                      },
                      ':not(:first-of-type)': {
                        marginLeft: 10,
                      },
                      '> img': {
                        borderRadius: rem(80),
                        border: '2px solid #000',
                        height: 'auto',
                        width: '100%',
                      },
                    },
                  })}
                  type='small'
                />
              </div>
            )}
          />
        </PcHidingContainer>
      )}

      <MobileContainer
        ref={mobileRef}
        data-testid="mobile-header-top"
        css={scaledCss({
          '> div > div': {
            alignItems: 'flex-start',
          },
        })}
      >
        <MobileNavigationBar
          transparent
          rightChildren={hasOtherGames ? (
            <>
              <DevsistersGamesButton
                onClick={toggleGames}
                data-testid="devsisters-games-button"
              >
                <DevsistersGamesIcon />
              </DevsistersGamesButton>
              <DimmedPopup
                hide={!openGames}
                setOpenGames={setOpenGames}
                hideDirection="right"
                onCloseButtonClick={toggleGames}
                css={css({ zIndex: 5 })}
              >
                <div>
                  <div css={scaledCss({
                    display: 'flex',
                    alignItems: 'center',
                    height: [100, '100px'],
                  })}>
                    <DevsistersGamesIcon css={scaledCss({
                      height: [30, '30px'],
                    })} />
                  </div>
                  <DevsistersGames onClickItem={closeGames} />
                </div>
              </DimmedPopup>
            </>
          ) : undefined}
        />
      </MobileContainer>
      {attachMobileHiding && (
        <MobileHidingContainer
          hide={mobileTopNavbarInView}
          data-testid="mobile-header-scroll"
        >
          <MobileNavigationBar
            rightChildren={collapseDownloads ? (
              <DownloadButton />
            ) : (
              <DownloadButtons
                type='small'
                css={css({
                  alignItems: 'center',
                  '> a': {
                    width: 114,
                    height: 40,
                    boxSizing: 'border-box',
                    boxShadow: 'none',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '@media (min-width: 640px)': {
                      width: 130,
                      height: 54,
                    },
                    '@media (min-width: 1200px)': {
                      width: 144,
                      height: 50,
                    },
                    ':not(:first-of-type)': {
                      marginLeft: 10,
                    },
                    '> img': {
                      borderRadius: rem(80),
                      border: '2px solid #000',
                      height: 'auto',
                      width: '100%',
                    },
                  },
                })} />
            )}
          />
        </MobileHidingContainer>
      )}

      <DimmedPopup
        hide={!openNav}
        hideDirection="left"
        onCloseButtonClick={toggleNav}>
        <MobileNavigation testId="mobile-navigation">
          <NavigationMenu
            variant="mobile"
            onMenuItemClick={closeNav}
          />
        </MobileNavigation>
      </DimmedPopup>
      <BodyPortal>
        <HamburgerContainer>
          <Hamburger
            className="hamburger-button"
            onClick={toggleNav}
            active={openNav}
            css={css({
              fontSize: [scale(35), 30],
            })}
          />
        </HamburgerContainer>
      </BodyPortal>
    </>
  );
};

export default Header;

const HamburgerContainer = styled('div')(
  css({
    display: ['flex', 'flex', 'none'],
    alignItems: 'center',
  }),
  skin({
    blackSquare: css({
      padding: 16,
      height: [scale(60), 48],
      position: 'fixed',
      top: [scale(-5), 0],
      left: 0,
      color: '#fff',
      zIndex: 4,
    }),
  }),
);
