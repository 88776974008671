exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-brixity-tsx": () => import("./../../../src/pages/brixity.tsx" /* webpackChunkName: "component---src-pages-brixity-tsx" */),
  "component---src-pages-cookierun-tsx": () => import("./../../../src/pages/cookierun.tsx" /* webpackChunkName: "component---src-pages-cookierun-tsx" */),
  "component---src-pages-cookierunpuzzle-tsx": () => import("./../../../src/pages/cookierunpuzzle.tsx" /* webpackChunkName: "component---src-pages-cookierunpuzzle-tsx" */),
  "component---src-pages-cookieruntoa-tsx": () => import("./../../../src/pages/cookieruntoa.tsx" /* webpackChunkName: "component---src-pages-cookieruntoa-tsx" */),
  "component---src-pages-en-brixity-mdx": () => import("./../../../src/pages/en/brixity.mdx" /* webpackChunkName: "component---src-pages-en-brixity-mdx" */),
  "component---src-pages-en-cookierun-mdx": () => import("./../../../src/pages/en/cookierun.mdx" /* webpackChunkName: "component---src-pages-en-cookierun-mdx" */),
  "component---src-pages-en-cookierunpuzzle-mdx": () => import("./../../../src/pages/en/cookierunpuzzle.mdx" /* webpackChunkName: "component---src-pages-en-cookierunpuzzle-mdx" */),
  "component---src-pages-en-cookieruntoa-mdx": () => import("./../../../src/pages/en/cookieruntoa.mdx" /* webpackChunkName: "component---src-pages-en-cookieruntoa-mdx" */),
  "component---src-pages-en-witchscastle-mdx": () => import("./../../../src/pages/en/witchscastle.mdx" /* webpackChunkName: "component---src-pages-en-witchscastle-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ja-brixity-mdx": () => import("./../../../src/pages/ja/brixity.mdx" /* webpackChunkName: "component---src-pages-ja-brixity-mdx" */),
  "component---src-pages-ja-cookierun-mdx": () => import("./../../../src/pages/ja/cookierun.mdx" /* webpackChunkName: "component---src-pages-ja-cookierun-mdx" */),
  "component---src-pages-ja-cookierunpuzzle-mdx": () => import("./../../../src/pages/ja/cookierunpuzzle.mdx" /* webpackChunkName: "component---src-pages-ja-cookierunpuzzle-mdx" */),
  "component---src-pages-ja-witchscastle-mdx": () => import("./../../../src/pages/ja/witchscastle.mdx" /* webpackChunkName: "component---src-pages-ja-witchscastle-mdx" */),
  "component---src-pages-ko-brixity-mdx": () => import("./../../../src/pages/ko/brixity.mdx" /* webpackChunkName: "component---src-pages-ko-brixity-mdx" */),
  "component---src-pages-ko-cookierun-mdx": () => import("./../../../src/pages/ko/cookierun.mdx" /* webpackChunkName: "component---src-pages-ko-cookierun-mdx" */),
  "component---src-pages-ko-cookierunpuzzle-mdx": () => import("./../../../src/pages/ko/cookierunpuzzle.mdx" /* webpackChunkName: "component---src-pages-ko-cookierunpuzzle-mdx" */),
  "component---src-pages-ko-cookieruntoa-mdx": () => import("./../../../src/pages/ko/cookieruntoa.mdx" /* webpackChunkName: "component---src-pages-ko-cookieruntoa-mdx" */),
  "component---src-pages-ko-witchscastle-mdx": () => import("./../../../src/pages/ko/witchscastle.mdx" /* webpackChunkName: "component---src-pages-ko-witchscastle-mdx" */),
  "component---src-pages-th-cookierun-mdx": () => import("./../../../src/pages/th/cookierun.mdx" /* webpackChunkName: "component---src-pages-th-cookierun-mdx" */),
  "component---src-pages-th-cookierunpuzzle-mdx": () => import("./../../../src/pages/th/cookierunpuzzle.mdx" /* webpackChunkName: "component---src-pages-th-cookierunpuzzle-mdx" */),
  "component---src-pages-th-witchscastle-mdx": () => import("./../../../src/pages/th/witchscastle.mdx" /* webpackChunkName: "component---src-pages-th-witchscastle-mdx" */),
  "component---src-pages-witchscastle-tsx": () => import("./../../../src/pages/witchscastle.tsx" /* webpackChunkName: "component---src-pages-witchscastle-tsx" */),
  "component---src-pages-zh-hant-cookierun-mdx": () => import("./../../../src/pages/zh-Hant/cookierun.mdx" /* webpackChunkName: "component---src-pages-zh-hant-cookierun-mdx" */),
  "component---src-pages-zh-hant-cookierunpuzzle-mdx": () => import("./../../../src/pages/zh-Hant/cookierunpuzzle.mdx" /* webpackChunkName: "component---src-pages-zh-hant-cookierunpuzzle-mdx" */),
  "component---src-pages-zh-hant-witchscastle-mdx": () => import("./../../../src/pages/zh-Hant/witchscastle.mdx" /* webpackChunkName: "component---src-pages-zh-hant-witchscastle-mdx" */)
}

