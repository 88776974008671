import styled from '@emotion/styled';

import { MOBILE_WIDTH, PC_WIDTH } from '~/src/screen';
import { scaledCss, css } from '~/src/css';
import { skin } from '~/src/theme';

const ContentBox = styled('div')(
  scaledCss({
    maxWidth: [MOBILE_WIDTH, PC_WIDTH],
  }),
  skin({
    blackSquare: css({
      paddingX: [8, 30],
      margin: '0 auto',
    }),
  }),
);

export default ContentBox;
