import React from 'react';
import styled from '@emotion/styled';

import { css, scale } from '~/src/css';
import { skin } from '~/src/theme';
import { useTexts } from '~/src/i18n';
import Link from '~/src/components/Link';
import { useOtherGames } from '~/src/games';

interface DevsistersGamesProps {
  className?: string;
  onClickItem?: () => void;
}

const Container = styled('div')(
  css({
    width: ['auto', 'auto', 480],
    position: [undefined, undefined, 'absolute'],
    top: [undefined, undefined, '100%'],
    left: [undefined, undefined, 0],
    background: [undefined, undefined, 'rgba(25,29,36,0.95)'],
    paddingBottom: [100, 0, 0],
  }),
);

const DevsistersGames: React.FC<DevsistersGamesProps> = ({
  className,
  onClickItem,
}) => {
  const otherGames = useOtherGames();
  return (
    <Container
      className={className}
      data-testid="devsisters-games-container"
    >
      {otherGames.map(game => (
        <DevsistersGamesItem
          key={game.id}
          path={game.path}
          image={require('!!file-loader!~/src/images/' + game.image).default}
          title={game.title}
          description={game.description}
          status={game.status as DevsistersGamesItemStatus}
          onClick={onClickItem}
        />
      ))}
    </Container>
  );
};

export default DevsistersGames;

type DevsistersGamesItemStatus = 'pre' | 'live' | 'upcoming';
interface DevsistersGamesItemProps {
  path: string;
  image: string;
  title: string;
  description: string;
  status: DevsistersGamesItemStatus;
  onClick?: () => void;
}
const DevsistersGamesItem: React.FC<DevsistersGamesItemProps> = ({
  path,
  image,
  title,
  description,
  status,
  onClick,
}) => {
  const t = useTexts();
  return (
    <DevsistersGamesItemContainer to={path} onClick={onClick} className='game-nav-link' target='_self'>
      <DevsistersGamesItemImage src={image} />
      <DevsistersGamesItemTitleAndDescription>
        <div>
          <DevsistersGamesItemTitle>{title}</DevsistersGamesItemTitle>
          <DevsistersGamesItemDescription>{description}</DevsistersGamesItemDescription>
        </div>
        {status === 'pre' && (
          <div>
            <DevsistersGamesItemPreRegistrationBadge>
              {t`games.pre.label`}
              <DevsistersGamesItemBadgeArrow viewBox="0 0 10 11">
                <path d="M-0.000,10.392 C-0.000,9.884 -0.000,0.970 -0.000,0.620 C-0.000,0.166 0.483,-0.172 0.962,0.093 C1.345,0.306 9.002,4.602 9.655,4.970 C10.084,5.211 10.077,5.787 9.655,6.028 C9.185,6.298 1.499,10.616 0.942,10.916 C0.535,11.136 -0.000,10.893 -0.000,10.392 Z" />
              </DevsistersGamesItemBadgeArrow>
            </DevsistersGamesItemPreRegistrationBadge>
          </div>
        )}
        {status === 'upcoming' && (
          <div>
            <DevsistersGamesItemUpcomingBadge>
              {t`games.upcoming.label`}
              <DevsistersGamesItemBadgeArrow viewBox="0 0 10 11">
                <path d="M-0.000,10.392 C-0.000,9.884 -0.000,0.970 -0.000,0.620 C-0.000,0.166 0.483,-0.172 0.962,0.093 C1.345,0.306 9.002,4.602 9.655,4.970 C10.084,5.211 10.077,5.787 9.655,6.028 C9.185,6.298 1.499,10.616 0.942,10.916 C0.535,11.136 -0.000,10.893 -0.000,10.392 Z" />
              </DevsistersGamesItemBadgeArrow>
            </DevsistersGamesItemUpcomingBadge>
          </div>
        )}
      </DevsistersGamesItemTitleAndDescription>
    </DevsistersGamesItemContainer>
  );
};

const DevsistersGamesItemContainer = styled(Link)(
  css({
    paddingX: [0, 20, 20],
    paddingY: [20, 20, 20],
    display: 'flex',
    textDecoration: 'none',
    borderTop: '1px solid #45464c',
  }),
);

const DevsistersGamesItemImage = styled.img(
  css({
    marginRight: [15, 15, 20],
    width: [100, 110, 110],
    height: [100, 110, 110],
    borderRadius: [10, 10, 10],
  }),
);

const DevsistersGamesItemTitleAndDescription = styled.div(
  skin({
    blackSquare: css({
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }),
  }),
);

const DevsistersGamesItemTitle = styled.h1(
  css({
    margin: 0,
    fontSize: [16, 24, 20],
    fontWeight: 700,
    '&:lang(ja)': {
      fontSize: [15, 24, 18],
    },
  }),
  skin({
    blackSquare: css({
      color: 'primary',
    }),
  }),
);

const DevsistersGamesItemDescription = styled.p(
  css({
    margin: 0,
    fontSize: [15, 18, 15],
    whiteSpace: 'pre-line',
    '&:lang(ja)': {
      fontSize: [14, 18, 15],
    },
  }),
  skin({
    blackSquare: css({
      color: 'normal',
    }),
  }),
);

const DevsistersGamesItemPreRegistrationBadge = styled.span(
  css({
    display: 'inline-block',
    fontSize: [13, 13, 15],
    paddingY: 4,
    paddingX: 12,
    borderRadius: 20,
    fontWeight: 700,
    letterSpacing: '-0.01em',
  }),
  skin({
    blackSquare: css({
      color: 'badge',
      background: 'badge',
    }),
  }),
);

const DevsistersGamesItemUpcomingBadge = styled.span(
  css({
    display: 'inline-block',
    fontSize: [13, 13, 15],
    paddingY: 4,
    paddingX: 12,
    borderRadius: 20,
    fontWeight: 700,
    letterSpacing: '-0.01em',
  }),
  skin({
    blackSquare: css({
      color: 'badge',
      background: '#EF8855',
    }),
  }),
);

const DevsistersGamesItemBadgeArrow = styled.svg(
  css({
    width: 10,
    height: 11,
    marginLeft: 8,
  }),
);
