import React, { MouseEventHandler } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

interface HamburgerProps {
  active?: boolean;
  duration?: number;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const BASE_WIDTH = 62;
const BASE_HEIGHT = 44;
const BASE_LINE_WIDTH = 7;
const BASE_LINE_SPACING = 12;

const WIDTH = `${BASE_WIDTH / BASE_HEIGHT}em`;
const MARGIN_TOP = `-${(BASE_LINE_WIDTH / 2) / BASE_HEIGHT}em`;
const LINE_WIDTH = `${BASE_LINE_WIDTH / BASE_HEIGHT}em`;
const OFFSET = `-${(BASE_LINE_WIDTH + BASE_LINE_SPACING) / BASE_HEIGHT}em`;

const Container = styled.button`
  display: inline-block;
  color: currentColor;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  overflow: visible;
  outline: none;
  line-height: 0;
`;

const Box = styled.div`
  width: ${WIDTH};
  height: 1em;
  display: inline-block;
  position: relative;
`;

const Inner = styled('div')`
  /* Base */
  top: 50%;
  margin-top: ${MARGIN_TOP};
  &:before, &:after {
    content: "";
    display: block;
  }
  &, &:before, &:after {
    position: absolute;
    width: 100%;
    background-color: currentColor;
    height: ${LINE_WIDTH};
    border-radius: ${LINE_WIDTH};
    transition-property: transform;
    transition-duration: ${(props: HamburgerProps) => props.duration || 0.15}s;
    transition-timing-function: ease;
  }
  &:before {
    top: ${OFFSET};
  }
  &:after {
    bottom: ${OFFSET};
  }

  /* Vortex */
  ${props => props.active && css`
    transform: rotate(765deg);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    &:before,
    &:after {
      transition-delay: 0s;
    }
    &:before {
      top: 0;
      opacity: 0;
    }
    &:after {
      bottom: 0;
      transform: rotate(90deg);
    }
  `}
`;

const Hamburger: React.FC<HamburgerProps> = ({
  active = false,
  duration = 0.15,
  className,
  onClick,
}) => {
  return (
    <Container className={className} onClick={onClick}>
      <Box>
        <Inner active={active} duration={duration}/>
      </Box>
    </Container>
  );
};

export default Hamburger;
