import React from 'react';
import styled from '@emotion/styled';
import $ from 'text2vdom';

import { css, fontFamily } from '~/src/css';
import { skin, SkinTheme } from '~/src/theme';

import MobileLineBreak from '~/src/components/MobileLineBreak';

export interface SectionBaseProps {
  title: string;
  description?: string;
  id?: string;
  className?: string;
  Tag?: keyof JSX.IntrinsicElements;
  children: React.ReactElement;
}

const BaseContainer = styled('section')(
  skin({
    blackSquare: css({
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingY: [30, 40],
    }),
  }),
  css({
    'br.mobile-line-break': {
      display: ['block', 'none'],
    },
  }),
);

const ScrollTarget = styled('div')(
  (_) => css({
    position: 'absolute',
    width: '100%',
    zIndex: -1,
  }),
  (props: { 'data-scroll-target': 'center' | 'start' }) => skin({
    blackSquare: css({
      height: [40, 'navbar'],
      ...props['data-scroll-target'] === 'start' && {
        bottom: '100%',
      },
      ...props['data-scroll-target'] === 'center' && {
        top: '50%',
        transform: 'translateY(-100%)',
      },
    }),
  })(props),
);

const Header = styled('div')(
  skin({
    blackSquare: css({
      textAlign: 'center',
      marginBottom: [10, 20],
    }),
  }),
);

const Title = styled('h1')(
  skin({
    blackSquare: (theme: SkinTheme<'blackSquare'>) => css({
      display: 'inline',
      position: 'relative',
      margin: 0,
      fontFamily: 'title',
      fontSize: [30, 40],
      fontWeight: 400,
      textAlign: 'center',
      lineHeight: 1,
      whiteSpace: 'pre-line',
      paddingX: 10,
      ...fontFamily('CookieRun Bold', 'ko'),
      ...fontFamily('CookieRun Bold', 'en'),
      ':lang(ja)': {
        fontWeight: 700,
        ...fontFamily('Noto Sans JP', 'ja'),
      },
      ':lang(zh-Hant)': {
        fontWeight: 700,
        ...fontFamily('Noto Sans TC', 'zh-Hant'),
      },
      ':lang(th)': {
        fontWeight: 700,
        ...fontFamily('Noto Sans Thai', 'th'),
      },
      ...theme.underline('section.active &'),
    }),
  }),
);

const Description = styled('p')(
  skin({
    blackSquare: css({
      marginTop: 18,
      fontSize: 18,
      textAlign: 'center',
      whiteSpace: 'pre-line',
    }),
  }),
);

const ContentWrapper = styled('div')(
  skin({
    blackSquare: css({
      width: '100%',
    }),
  }),
);

const SectionBase: React.FC<SectionBaseProps> = ({
  id,
  title,
  description,
  children,
  className,
  Tag = 'section',
}) => {
  const Container = BaseContainer as any;
  const mbr = () => <MobileLineBreak/>;

  return (
    <Container id={id} className={className} as={Tag}>
      <ScrollTarget data-scroll-target="start"/>
      <ScrollTarget data-scroll-target="center"/>
      <Header>
        <Title>
          {$(title, { mbr })}
        </Title>
        {description && (
          <Description>
            {$(description, { mbr })}
          </Description>
        )}
      </Header>
      <ContentWrapper>
        {children}
      </ContentWrapper>
    </Container>
  );
};

export default SectionBase;
