import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import { useFrontmatter } from '~/src/frontmatter';
import { useLocalizedNode } from '~/src/i18n';
import { TwitterCardQuery } from '~/src/generated/types/gatsby';

import OpenGraph from '~/src/components/OpenGraph';

interface TwitterCardProps {
  location: Location;
}

const TwitterCard: React.FC<TwitterCardProps> = ({ location }) => {
  const data = useStaticQuery<TwitterCardQuery>(graphql`
    query TwitterCard {
      allCompanyInfo {
        nodes {
          locale
          games {
            id
            title
          }
        }
      }
    }
  `);
  const { games } = useLocalizedNode(data.allCompanyInfo.nodes);
  const currentGame = games.find(game => location.pathname.includes(game.id));
  if (!currentGame) {
    throw new Error('게임 ID가 현재 URL Path와 일치하는지 확인하세요');
  }

  const { locale, description, twitter, googleAppId, appleAppId, onelink } = useFrontmatter();
  const hasAppId = Boolean(googleAppId || appleAppId);

  return (
    <Helmet>
      <OpenGraph location={location} />

      <meta name="twitter:card" content={hasAppId ? 'app' : 'summary'}/>
      <meta name="twitter:site" content={twitter}/>
      <meta name="twitter:creator" content={twitter}/>
      <meta name="twitter:description" content={description}/>

      <meta name="twitter:app:country" content={locale.toUpperCase()}/>

      {appleAppId && (
        <Helmet>
          <meta name="twitter:app:name:iphone" content={currentGame.title}/>
          <meta name="twitter:app:id:iphone" content={appleAppId}/>
          <meta name="twitter:app:url:iphone" content={onelink}/>
          <meta name="twitter:app:name:ipad" content={currentGame.title}/>
          <meta name="twitter:app:id:ipad" content={appleAppId}/>
          <meta name="twitter:app:url:ipad" content={onelink}/>
        </Helmet>
      )}

      {googleAppId && (
        <Helmet>
          <meta name="twitter:app:name:googleplay" content={currentGame.title}/>
          <meta name="twitter:app:id:googleplay" content={googleAppId}/>
          <meta name="twitter:app:url:googleplay" content={onelink}/>
        </Helmet>
      )}
    </Helmet>
  );
};

export default TwitterCard;
