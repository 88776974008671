import { useState, useEffect, useCallback } from 'react';

export default function useCarouselAutoplay(
  currentIndex: number,
  setCurrentIndex: (index: number) => void,
  totalCount: number,
  interval: number,
  autostart = true,
) {
  const [autoplay, setAutoplay] = useState(autostart);
  const pauseAutoplay = useCallback(() => setAutoplay(false), []);
  const resumeAutoplay = useCallback(() => setAutoplay(true), []);
  const toggleAutoplay = useCallback(() => setAutoplay(!autoplay), [autoplay]);
  useEffect(() => {
    if (!autoplay) return;
    const intervalId = setInterval(
      () => setCurrentIndex((currentIndex + 1) % totalCount),
      interval,
    );
    return () => clearInterval(intervalId);
  }, [autoplay, currentIndex, totalCount, interval, setCurrentIndex]);
  return {
    pauseAutoplay,
    resumeAutoplay,
    toggleAutoplay,
  };
}
