import { useEffect } from 'react';

type SwipeDirection = 'left' | 'right' | 'up' | 'down';
interface SwipeHandler {
  (direction: SwipeDirection): void;
}

export default function useSwipeEvent(
  target: React.RefObject<HTMLElement>,
  handler: SwipeHandler,
  distThreshold: number = 100,
  timeThreshold: number = 500,
) {
  useEffect(() => {
    if (!target.current) return;
    const targetElement = target.current;
    const touchStartHandler = (e: TouchEvent) => {
      const start = getTouchInfo(e);
      const touchEndHandler = (e: TouchEvent) => {
        const end = getTouchInfo(e);
        const diff = diffAll(start, end);
        event: try {
          if (diff.time > timeThreshold) break event;
          if (
            (Math.abs(diff.pageX) < distThreshold) &&
            (Math.abs(diff.pageY) < distThreshold)
          ) break event;
          const direction = getDirection(diff.pageX, diff.pageY);
          handler(direction);
        } finally {
          window.removeEventListener('touchend', touchEndHandler);
        }
      };
      window.addEventListener('touchend', touchEndHandler);
    };
    targetElement.addEventListener('touchstart', touchStartHandler);
    return () => targetElement.removeEventListener('touchstart', touchStartHandler);
  }, [distThreshold, timeThreshold, target, handler]);
}

function getTouchInfo(e: TouchEvent) {
  const touch = e.changedTouches[0];
  const { pageX, pageY } = touch;
  const time = Date.now();
  return { pageX, pageY, time };
}

type NumDict = { [key: string]: number; };
function diffAll<T extends NumDict>(start: T, end: T): T {
  const c = {} as any;
  for (const key in start) c[key] = end[key] - start[key];
  return c as T;
}

function getDirection(dx: number, dy: number): SwipeDirection {
  if (Math.abs(dx) > Math.abs(dy)) return dx > 0 ? 'right' : 'left';
  return dy > 0 ? 'down' : 'up';
}
