import React from 'react';
import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';

import { ImageSharp } from '~/src/generated/types/gatsby';

import { css } from '~/src/css';
import { skin, SkinTheme } from '~/src/theme';

interface ThumbnailCardProps {
  url: string;
  caption: string;
  sharp: Partial<ImageSharp>;
}

const LinkContainer = styled('a')(
  skin({
    blackSquare: (theme: SkinTheme<'blackSquare'>) => css({
      display: 'inline-block',
      border: '1px solid #ddd',
      overflow: 'hidden',
      textDecoration: 'none',
      color: '#000',
      ':hover .gatsby-image-wrapper img': {
        transform: 'scale(1.1)',
      },
    }),
  }),
);

const Figure = styled('figure')(
  skin({
    blackSquare: css({
      margin: 0,
      width: '100%',
      height: '100%',
      background: '#fff',
    }),
  }),
);

const Image = styled(GatsbyImage)(
  skin({
    blackSquare: css({
      'img': {
        transition: 'transform 0.3s ease-out !important',
      },
    }),
  }),
);

const Caption = styled('figcaption')(
  skin({
    blackSquare: css({
      paddingY: 20,
      paddingX: 10,
    }),
  }),
);

const CaptionText = styled('span')(
  skin({
    blackSquare: (theme: SkinTheme<'blackSquare'>) => css({
      fontSize: 18,
      whiteSpace: 'pre-line',
      letterSpacing: '-0.025em',
      ...theme.underline('a:hover &'),
    }),
  }),
);

const ThumbnailCard: React.FC<ThumbnailCardProps> = ({
  caption,
  sharp,
  url,
}) => {
  return (
    <LinkContainer href={url} target="_blank" rel="noopener" className='hotissue-link'>
      <Figure>
        <Image image={sharp.gatsbyImageData} alt={'thumbnail'} />
        <Caption>
          <CaptionText
            dangerouslySetInnerHTML={{ __html: caption }}>
          </CaptionText>
        </Caption>
      </Figure>
    </LinkContainer>
  );
};

export default ThumbnailCard;
