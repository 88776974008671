import { rem } from 'polished';
import _css, { SystemStyleObject } from '@styled-system/css';

import { MOBILE_PX_RATIO } from '~/src/screen';

const RESPONSIVE_PX = Object.freeze([...Array(1000)].map((_, i) => (
  rem(i)
)));
const responsiveTheme = {
  space: RESPONSIVE_PX,
  sizes: RESPONSIVE_PX,
  fontSizes: RESPONSIVE_PX,
  borderWidths: RESPONSIVE_PX,
  raddi: RESPONSIVE_PX,
};
export const css: typeof _css = args => (props = {}) => {
  return _css(args)({
    ...props,
    theme: {
      // @ts-ignore
      ...props.theme,
      ...responsiveTheme,
    },
  });
};

export const scale = (px: number) => `${px * MOBILE_PX_RATIO}vw`;
const SCALED_PX = Object.freeze([...Array(1000)].map((_, i) => (
  scale(i)
)));
const scaledTheme = {
  space: SCALED_PX,
  sizes: SCALED_PX,
  fontSizes: SCALED_PX,
  borderWidths: SCALED_PX,
  raddi: SCALED_PX,
};
// TODO: 첫 번째 breakpoint만 변환하는 똑똑한 scaledCss system 정의하기
export const scaledCss: typeof _css = args  => (props = {}) => {
  return _css(args)({
    ...props,
    theme: {
      // @ts-ignore
      ...props.theme,
      ...scaledTheme,
    },
  });
};

export const hover = (args: SystemStyleObject) => {
  return {
    ':hover': args,
    ':focus': args,
  };
};

export const fontFamily = (fontName: string, locale: string) => {
  return {
    [`:lang(${locale})`]: {
      fontFamily: `"${fontName}__subset", "${fontName}", sans-serif`,
    },
  };
};
