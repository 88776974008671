import React from 'react';
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';

const Link: React.RefForwardingComponent<
HTMLAnchorElement,
Omit<GatsbyLinkProps<any>, 'ref'>
> = ({
  to,
  activeClassName,
  partiallyActive,
  children,
  ...otherProps
}, forwardedRef) => {
  const internal = /^\/(?!\/)/.test(to);

  return internal ? (
    <GatsbyLink
      ref={forwardedRef as any}
      to={to}
      activeClassName={activeClassName}
      partiallyActive={partiallyActive}
      {...otherProps}
    >
      {children}
    </GatsbyLink>
  ) : (
    <a
      ref={forwardedRef}
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      {...otherProps}
    >
      {children}
    </a>
  );
};

export default React.forwardRef(Link);
