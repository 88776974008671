import { useState, useCallback } from 'react';

import { CarouselMode } from './types';
import useCarouselAutoplay from './useCarouselAutoplay';
import useCarouselItems, { CarouselItems } from './useCarouselItems';


type ChangeEventHandler = (index: number) => void;

export default function useCarousel(mode: CarouselMode, onChange?: ChangeEventHandler) {
  const [currentSlideIndex, _setCurrentSlideIndex] = useState(0);
  const setCurrentSlideIndex = useCallback<ChangeEventHandler>(index => {
    if (index === currentSlideIndex) {
      return;
    }
    _setCurrentSlideIndex(index);
    if (onChange) {
      onChange(index);
    }
  }, [onChange]);

  const carouselItems:CarouselItems = useCarouselItems(mode);
  const { pauseAutoplay, resumeAutoplay } = useCarouselAutoplay(
    currentSlideIndex,
    setCurrentSlideIndex,
    carouselItems.length,
    2000,
    false,
  );
  const totalCount = carouselItems.length;
  const setCurrentSlideIndexByUser = useCallback((index: number) => {
    setCurrentSlideIndex(index);
    pauseAutoplay();
  }, [pauseAutoplay, setCurrentSlideIndex]);
  const goPrev = useCallback(() => {
    setCurrentSlideIndexByUser((totalCount + currentSlideIndex - 1) % totalCount);
  }, [setCurrentSlideIndexByUser, currentSlideIndex, totalCount]);
  const goNext = useCallback(() => {
    setCurrentSlideIndexByUser((currentSlideIndex + 1) % totalCount);
  }, [setCurrentSlideIndexByUser, currentSlideIndex, totalCount]);
  return {
    carouselItems,
    currentSlideIndex,
    setCurrentSlideIndex: setCurrentSlideIndexByUser,
    totalCount,
    goPrev,
    goNext,
    pauseAutoplay,
    resumeAutoplay,
  };
}
