import React from 'react';
import { Global } from '@emotion/react';

import CookieRunFont from './CookierunFont';
import NotoSansFont from './NotoSansFont';

const Fonts: React.FC = () => {
  return (
    <>
      <Global styles={{
        '*': {
          fontFamily: 'inherit',
        },
        'body': {
          backgroundColor: '#191d24',
          textRendering: 'optimizeLegibility',
          WebkitFontSmoothing: 'antialiased',
          wordBreak: 'break-word',
          fontFamily: '"Noto Sans__subset", "Noto Sans", sans-serif',
        },
        'body:lang(ko)': {
          wordBreak: 'keep-all',
          fontFamily: '"Noto Sans KR__subset", "Noto Sans KR", sans-serif',
        },
        'body:lang(ja)': {
          fontFamily: '"Noto Sans JP__subset", "Noto Sans JP", sans-serif',
        },
        'body:lang(zh-Hant)': {
          fontFamily: '"Noto Sans TC__subset", "Noto Sans TC", sans-serif',
        },
        'body:lang(th)': {
          fontFamily: '"Noto Sans Thai__subset", "Noto Sans Thai", sans-serif',
        },
      }}/>
      <CookieRunFont/>
      <NotoSansFont/>
    </>
  );
};

export default Fonts;
