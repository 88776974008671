import React, { ComponentType, useState } from 'react';

export const isServerSide = typeof window === 'object';

export const OnSSR: React.FC = ({ children }) => (
  <>{isServerSide ? children : null}</>
);

export const NoSSR: React.FC = ({ children }) => (
  <>{isServerSide ? null : children}</>
);

type Maybe<T> = T | null;

export function useLazyComponent<T extends ComponentType<any>>(factory: () => Promise<{ default: T }>) {
  const [Component, setComponent]= useState<Maybe<T>>(null);
  async function loadComponent() {
    const { default: loaded } = await factory();
    setComponent(loaded);
  }
  return [Component, loadComponent] as const;
}
