import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import ReactDOM from 'react-dom';

interface BodyPortalProps {
  children?: React.ReactElement;
}

const BodyPortal = forwardRef<HTMLDivElement | null, BodyPortalProps>(
  function BodyPortal({ children }, ref) {
    const [dom, setDom] = useState<HTMLDivElement | null>(null);
    useImperativeHandle(ref, () => dom, [dom]);
    useEffect(() => {
      const dom = document.createElement('div');
      setDom(dom);
      document.body.appendChild(dom);
      return () => void document.body.removeChild(dom);
    }, []);
    return dom && ReactDOM.createPortal(children, dom);
  },
);

export default BodyPortal;
