import React from 'react';
import { Global, css } from '@emotion/react';
import { useLocale } from '@devsisters/gatsby-plugin-i18n';

import notoSansThaiWoff2Url from './NotoSansThaiRegular.subset.woff2';
import notoSansThaiWoffUrl from './NotoSansThaiRegular.subset.woff';
import notoSansThaiOtfUrl from './NotoSansThaiRegular.subset.otf';
import notoSansThaiTtfUrl from './NotoSansThai-Regular.ttf';

import notoSansThaiBoldWoff2Url from './NotoSansThaiBold.subset.woff2';
import notoSansThaiBoldWoffUrl from './NotoSansThaiBold.subset.woff';
import notoSansThaiBoldOtfUrl from './NotoSansThaiBold.subset.otf';
import notoSansThaiBoldTtfUrl from './NotoSansThai-Bold.ttf';

const NotoSansFont: React.FC = () => {
  const locale = useLocale();
  return (
    <Global styles={css`
      ${locale === 'en' && css`
        @import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,700&display=swap&subset=latin');
      `}
      ${locale === 'ko' && css`
        @import url('https://fonts.googleapis.com/css?family=Noto+Sans+KR:400,700&display=swap&subset=korean');
      `}
      ${locale === 'ja' && css`
        @import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700&display=swap&subset=japanese');
      `}
      ${locale === 'zh-Hant' && css`
        @import url('https://fonts.googleapis.com/css?family=Noto+Sans+TC:400,700&display=swap&subset=chinese-traditional');
      `}
      ${locale === 'th' && css`
        @font-face {
          font-family: "Noto Sans Thai";
          font-weight: 400;
          src: local("Noto Sans Thai"),
            url("${notoSansThaiWoff2Url}") format("woff2"),
            url("${notoSansThaiWoffUrl}") format("woff"),
            url("${notoSansThaiOtfUrl}") format("opentype"),
            url("${notoSansThaiTtfUrl}") format("truetype");
        }
        @font-face {
          font-family: "Noto Sans Thai";
          font-weight: 700;
          src: local("Noto Sans Thai"),
            url("${notoSansThaiBoldWoff2Url}") format("woff2"),
            url("${notoSansThaiBoldWoffUrl}") format("woff"),
            url("${notoSansThaiBoldOtfUrl}") format("opentype"),
            url("${notoSansThaiBoldTtfUrl}") format("truetype")
        }
      `}
    `}/>
  );
};

export default NotoSansFont;
