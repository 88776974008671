import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { CarouselMode } from './types';
import { useFrontmatter } from '~/src/frontmatter';
import { useLocale } from '@devsisters/gatsby-plugin-i18n';

export type CarouselItems = ReturnType<typeof useCarouselItems>;
export default function useCarouselItems(mode: CarouselMode) {
  const data = useStaticQuery(graphql`
    query getCarouselData {
      allPortraitItem: allStrapiGamesiteCarousel(filter: {type: {eq: "portrait"}}) {
        nodes {
          game
          environment
          locale
          components {
            title
            description
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 80,
                    width: 1080,
                    height: 1920,
                    breakpoints: [360, 640, 1200],
                    tracedSVGOptions: {
                      optTolerance: 2,
                      turdSize: 700,
                    }
                  )
                }
              }
            }
          }
        }
      }
      allLandscapeItem: allStrapiGamesiteCarousel(filter: {type: {eq: "landscape"}}) {
        nodes {
          game
          environment
          locale
          components {
            title
            description
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 80,
                    width: 1920,
                    height: 1080,
                    breakpoints: [360, 640, 1200],
                    tracedSVGOptions: {
                      optTolerance: 2,
                      turdSize: 700,
                    },
                  )
                }
              }
            }
          }
        }
      }
    }
  `);

  const gameId = useFrontmatter().gameId;
  const locale = useLocale();

  const carouselItems = useMemo(()=>{
    const targetNodes = (mode === 'portrait' ? data.allPortraitItem.nodes : data.allLandscapeItem.nodes)
      .find((node: any) =>
        (node.environment === (process.env.GATSBY_ENVIRONMENT ?? 'preview'))
        && node.game === gameId
        && node.locale.toLowerCase() === locale.toLowerCase(),
      );

    if (!targetNodes) {
      return [];
    }

    return targetNodes.components;
  }, [mode, data.allPortraitItem.nodes, data.allLandscapeItem.nodes, locale, gameId]);

  return carouselItems;
}
