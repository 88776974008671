import { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useI18n, useLocale } from '@devsisters/gatsby-plugin-i18n';

interface UseTexts {
  (locale?: string): typeof String.raw;
}
export const useTexts: UseTexts = locale => {
  const { translations, locale: contextLocaleString } = useI18n();
  const resource = translations[locale || contextLocaleString];
  return (...args) => {
    const key = String.raw(...args);
    if (!resource) return key;
    if (typeof resource[key] !== 'string') return key;
    return resource[key];
  };
};

interface LocalizedNode {
  locale: string;
}

export function filterByLocale<T = any>(arr: Array<T & LocalizedNode>, locale: string) {
  return arr.find(data => data.locale === locale);
}

export function useLocalizedNode<T = any>(arr: Array<T & LocalizedNode>) {
  const locale = useLocale();
  const localizedNode = arr.find(data => {
    if (!data.locale) {
      throw new Error('Object doesn\'t contain locale field. Did you forget to add `locale` into your query?');
    }
    return (data as LocalizedNode).locale === locale;
  });
  if (!localizedNode) {
    const defaultData = filterByLocale(arr, 'default');
    if (!defaultData) {
      throw new Error('LocalizedData must provider `locale: default` as fallback.');
    }
    return defaultData as T;
  }
  return localizedNode as T;
}

export function useLocaleRedirect(location: Location) {
  useEffect(() => {
    if (/(zh-Hant|zh-TW)/i.test(navigator.language)) {
      navigate(`/zh-Hant${location.pathname}`, { replace: true });
    } else {
      const [language] = navigator.language.split('-');
      const redirectLanguage = ['ko', 'en', 'ja', 'th']
        .find(supportedLanguage => supportedLanguage === language)
        || 'en';
      navigate(`/${redirectLanguage}${location.pathname}`, { replace: true });
    }
  }, [location]);
}
