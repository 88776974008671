import React from 'react';

import Sections from '~/src/components/Sections';
import Footer from '~/src/components/Footer';
import KeyVisual from '~/src/components/KeyVisual';
import Header from '~/src/components/Navigation/Header';
import Fonts from '~/src/components/Fonts';

import { createLayout } from './Layout';

const GameSiteLayout: React.FC = () => (
  <>
    <Fonts/>
    <Header/>
    <KeyVisual/>
    <Sections/>
    <Footer/>
  </>
);

export default createLayout(GameSiteLayout);
