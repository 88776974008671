import React from 'react';
import styled from '@emotion/styled';
import fromEntries from 'lodash/fromPairs';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocale, useTexts } from '@devsisters/gatsby-plugin-i18n';

import { HotIssueQuery } from '~/src/generated/types/gatsby';
import { skin } from '~/src/theme';
import { css, fontFamily } from '~/src/css';
import { useFrontmatter } from '~/src/frontmatter';
import ContentBox from '~/src/components/ContentBox';
import ThumbnailCard from '~/src/components/ThumbnailCard';
import SectionBase, { SectionBaseProps } from '~/src/components/Sections/base';

export interface HotIssueSectionProps extends SectionBaseProps {
  moreUrl?: string;
}

const Container = styled(SectionBase)(
  skin({
    blackSquare: css({
      background: 'bg2',
    }),
  }),
);

const PcMoreLink = styled('a')(
  skin({
    blackSquare: css({
      color: '#000',
      textDecoration: 'none',
      display: ['none', 'inline'],
      position: 'absolute',
      fontSize: 20,
      top: '-3.5em',
      right: '0.8em',
      whiteSpace: 'nowrap',
    }),
  }),
);

const MobileMoreLink = styled('a')(
  skin({
    blackSquare: css({
      display: ['inline-block', 'none'],
      color: '#161515',
      paddingY: 16,
      fontSize: 24,
      fontWeight: 400,
      textDecoration: 'none',
      border: '1px solid #ddd',
      background: '#fff',
      width: '100%',
      textAlign: 'center',
      // TODO: API 너무 구림 ㅠㅠ
      ...fontFamily('CookieRun Bold', 'ko'),
      ...fontFamily('CookieRun Bold', 'en'),
      ':lang(ja)': {
        fontWeight: 700,
        ...fontFamily('Noto Sans JP', 'ja'),
      },
      ':lang(zh-Hant)': {
        fontWeight: 700,
        ...fontFamily('Noto Sans TC', 'zh-Hant'),
      },
      ':lang(th)': {
        fontWeight: 700,
        ...fontFamily('Noto Sans Thai', 'th'),
      },
    }),
  }),
);

const AutoGrid = styled('div')(
  // Autoprefixer plugin 을 붙일 수가 없다니 끔-찍 하네요.
  css({
    display: '-ms-grid',
    msGridColumns: [
      '1fr',
      '1fr 1fr',
      '1fr 1fr 1fr 1fr',
    ],
    msGridRows: [
      '1fr 1fr 1fr 1fr',
      '1fr 1fr',
      '1fr',
    ],
    '> *': {
      margin: 15,
    },
    '@supports (display: grid)': {
      '&& > *': {
        margin: 0,
      },
    },
    ...fromEntries(
      [...Array(4)]
        .fill(null)
        .map((_, i) => i)
        .map(i => [
          `> *:nth-of-type(${i + 1})`,
          {
            msGridColumn: [
              `${i % 1 + 1}`,
              `${i % 2 + 1}`,
              `${i % 4 + 1}`,
            ],
            msGridRow: [
              `${~~(i / 1) + 1}`,
              `${~~(i / 2) + 1}`,
              `${~~(i / 4) + 1}`,
            ],
          },
        ]),
    ),
  }),
  css({
    display: 'grid',
    gap: 30,
    gridGap: 30,
    marginY: 20,
    gridTemplateColumns: [
      'repeat(1, 1fr)',
      'repeat(2, 1fr)',
      'repeat(4, 1fr)',
    ],
  }),
);

const HotIssueSection: React.FC<HotIssueSectionProps> = ({
  id,
  title,
  description,
  moreUrl,
}) => {
  const gameId = useFrontmatter().gameId;
  const t = useTexts();
  const locale = useLocale();
  const data = useStaticQuery<HotIssueQuery>(graphql`
    query HotIssue {
      allStrapiGamesiteHotissue {
        nodes {
          game
          environment
          locale
          hotissues {
            title
            link
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 90,
                    width: 500,
                    breakpoints: [500],
                    tracedSVGOptions: {optTolerance: 1, turdSize: 200},
                  )
                }
              }
            }
          }
        }
      }
    }
  `);

  const localIssues = data.allStrapiGamesiteHotissue.nodes
    ?.find((node: any) =>
      (node.environment === (process.env.GATSBY_ENVIRONMENT ?? 'preview'))
      && node.game === gameId
      && node.locale.toLowerCase() === locale.toLowerCase(),
    )?.hotissues
    .slice(0,4);

  return (
    <Container id={id} title={title} description={description}>
      <ContentBox>
        <div css={{
          position: 'relative',
        }}>
          {moreUrl && (
            <PcMoreLink href={moreUrl} target="_blank" rel="noopener" className='hotissue-more'>
              {t`section.hotissue.more`}
            </PcMoreLink>
          )}
          <AutoGrid>
            {localIssues?.map((issue, i) => (
              <ThumbnailCard
                key={`thumbnail-card-${i}`}
                url={issue.link}
                caption={issue.title}
                sharp={issue.image.localFile.childImageSharp}
              />
            ))}
          </AutoGrid>
          {moreUrl && (
            <MobileMoreLink href={moreUrl}>
              {t`section.hotissue.more`}
            </MobileMoreLink>
          )}
        </div>
      </ContentBox>
    </Container>
  );
};

export default HotIssueSection;
