import { useMediaQuery } from 'react-responsive';
import { rem } from 'polished';

export const MOBILE_WIDTH = 640;
export const MOBILE_SCREEN_WIDTH = 360;
export const PC_WIDTH = 1200;
export const PC_SCREEN_WIDTH = 1920;

export const MOBILE_PX_RATIO = 100 / MOBILE_WIDTH;

export function useMediaValue<T = any>(values: [T, T] | [T, T, T]) {
  const breakTablet = useMediaQuery({ minWidth: rem(MOBILE_WIDTH) });
  const breakPc = useMediaQuery({ minWidth: rem(PC_WIDTH) });
  const breaks = [breakTablet, breakPc].filter(Boolean);

  return values[Math.min(breaks.length, values.length - 1)];
}
