import React from 'react';
import styled from '@emotion/styled';

import { skin, SkinTheme } from '~/src/theme';
import { css, hover, fontFamily } from '~/src/css';
import { useFrontmatter } from '~/src/frontmatter';
import { MdxFrontmatterNavigationItems } from '~/src/generated/types/gatsby';
import useWatchingSection from '~/src/misc/useWatchingSection';

const OUTERLINK_PROPS = {
  target: '_blank',
  rel: 'noopener',
};

interface NavigationMenuProps {
  className?: string;
  variant: 'pc' | 'mobile';
  onMenuItemClick?: (item: MdxFrontmatterNavigationItems) => void;
}

interface DirectionVariant {
  variant: 'horizontal' | 'vertical';
}

const Container = styled('nav')(
  skin({
    blackSquare: css({
      textAlign: 'center',
      fontWeight: 400,
      ...fontFamily('CookieRun Bold', 'ko'),
      ...fontFamily('CookieRun Bold', 'en'),
      ':lang(ja)': {
        fontWeight: 700,
        ...fontFamily('Noto Sans JP', 'ja'),
      },
      ':lang(zh-Hant)': {
        fontWeight: 700,
        ...fontFamily('Noto Sans TC', 'zh-Hant'),
      },
      ':lang(th)': {
        fontWeight: 700,
        ...fontFamily('Noto Sans Thai', 'th'),
      },
    }),
  }),
);

const MenuItemList =  styled('ul')(
  (props: DirectionVariant) => skin({
    // @ts-ignore
    blackSquare: css({
      display: 'flex',
      listStyle: 'none',
      paddingLeft: 0,
      margin: 0,
      marginTop: [0, 30, 0],
      flexDirection: props.variant === 'horizontal'
        ? 'row'
        : 'column',
    })(props),
  }),
);

const MenuItem = styled('li')(
  (props: DirectionVariant) => skin({
    // @ts-ignore
    blackSquare: css({
      ...(props.variant === 'horizontal' ? {
        marginLeft: 40,
      } : {
        marginBottom: [30, 50],
      }),
    })(props),
  }),
);

const MenuLink = styled('a')(
  (props: NavigationMenuProps) => skin({
    // @ts-ignore
    blackSquare: (theme: SkinTheme<'blackSquare'>) => css({
      fontWeight: 'navbar',
      textDecoration: 'none',
      whiteSpace: 'nowrap',
      ...(props.variant === 'pc' ? {
        fontSize: 24,
        // 일본어 패치..
        ':lang(ja)': {
          fontSize: 19,
        },
        color: '#000',
        ...theme.underline('&:hover'),
      } : {
        fontSize: [33, 42],
        color: '#fff',
        ...hover({
          color: 'secondary',
        }),
      }),
    })(props),
  }),
);

const NavigationMenu: React.FC<NavigationMenuProps> = ({
  className,
  variant,
  onMenuItemClick,
}) => {
  const frontmatter = useFrontmatter();
  // 메뉴 아이템은 4개까지만 추가 가능합니다.
  const menuItems = frontmatter.navigation.items.slice(0, 4);
  const directionVariant = variant === 'pc' ? 'horizontal' : 'vertical';
  const { visibleSections } = useWatchingSection();

  return (
    <Container className={className} role="navigation">
      <MenuItemList variant={directionVariant}>
        {menuItems.map((item, index) => (
          <MenuItem key={index} variant={directionVariant}>
            <MenuLink
              onClick={(e) => {
                if (item.type === 'hash') {
                  e.preventDefault();
                  const elements = Array.from<HTMLElement>(document.querySelectorAll(item.url));
                  const section = visibleSections.find(s => elements.includes(s));
                  if (section) {
                    const scrollTargetBlock = section.offsetHeight >= (window.innerHeight / 2)
                      ? 'start'
                      : 'center'
                    ;
                    const scrollTarget = section.querySelector(`[data-scroll-target=${scrollTargetBlock}]`);
                    if (scrollTarget) {
                      scrollTarget.scrollIntoView({
                        behavior: 'smooth',
                        inline: 'start',
                        block: scrollTargetBlock,
                      });
                    }
                  }
                }
                onMenuItemClick && onMenuItemClick(item);
              }}
              variant={variant}
              className='gnb-link'
              href={item.url}
              {...item.type === 'outerlink' && OUTERLINK_PROPS}
            >
              {item.label}
            </MenuLink>
          </MenuItem>
        ))}
      </MenuItemList>
    </Container>
  );
};

export default NavigationMenu;
