import React, { createContext, useContext, useState, useEffect } from 'react';

export type Platform = (
  | 'unknown'
  | 'ios'
  | 'android'
  | 'ie' // 만악의 근원
);

const platformContext = createContext<Platform>('unknown');

export const PlatformProvider: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const [platform, setPlatform] = useState<Platform>('unknown');

  useEffect(() => {
    if (window.navigator.userAgent.includes('iPhone')) {
      setPlatform('ios');
    } else if (window.navigator.userAgent.includes('iPad')) {
      setPlatform('ios');
    } else if (window.navigator.userAgent.includes('Android')) {
      setPlatform('android');
    } else if (window.navigator.userAgent.includes('Trident')) {
      setPlatform('ie');
    } else {
      setPlatform('unknown');
    }
  }, []);

  return (
    <platformContext.Provider value={platform}>
      {children}
    </platformContext.Provider>
  );
};
PlatformProvider.displayName = 'PlatformProvider';

export default function usePlatform(): Platform {
  return useContext(platformContext);
}
