import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import styled from '@emotion/styled';
import $ from 'text2vdom';

import { BackgroundImageQuery } from '~/src/generated/types/gatsby';
import { css, scaledCss } from '~/src/css';
import { PC_SCREEN_WIDTH, MOBILE_SCREEN_WIDTH } from '~/src/screen';

import SectionBase, { SectionBaseProps } from './base';
import ContentBox from '~/src/components/ContentBox';
import MobileLineBreak from '~/src/components/MobileLineBreak';
import { convertToBgImage } from 'gbimage-bridge';
import { getImage } from 'gatsby-plugin-image';

export interface BackgroundImageSectionProps extends SectionBaseProps {
  description: string;
  image: {
    pc: string,
    mobile: string,
  };
}

interface BackgroundImageProps {
  id?: string; // workaround. see: https://github.com/timhagn/gatsby-background-image/pull/77
  minHeight: number;
}

const PcBackgroundImage = styled(BackgroundImage)(
  (props: BackgroundImageProps) => {
    const size = props.minHeight;
    return css({
      display: ['none', 'block'],
      minHeight: `${size}px`,
      height: `${size / PC_SCREEN_WIDTH * 100}vw`,
    })(props);
  },
);

const MobileBackgroundImage = styled(BackgroundImage)(
  (props: BackgroundImageProps) => {
    const size = props.minHeight;
    return css({
      display: ['block', 'none'],
      minHeight: `${size}px`,
      height: `${size / MOBILE_SCREEN_WIDTH * 100}vw`,
    })(props);
  },
);

const Description = styled('p')(
  css({
    textAlign: 'center',
    whiteSpace: 'pre-line',
    fontSize: [16, 18],
    lineHeight: 1.5,
    textShadow: '0 0 0.5em #fff',
    margin: 0,
    marginTop: [8, '8px'],
    '> br': {
      display: ['block', 'none'],
    },
  }),
);

const BackgroundImageSection: React.FC<BackgroundImageSectionProps> = ({
  id,
  title,
  description,
  image,
}) => {
  const data = useStaticQuery<BackgroundImageQuery>(graphql`
    query BackgroundImage {
      allPcBackgroundImage: allFile(filter: {
        relativeDirectory: { eq: "background" }
        relativePath: { regex: "/pc/" }
      }) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(
              quality: 80
              width: 1920
              breakpoints: [640, 1200]
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      allMobileBackgroundImage: allFile(filter: {
        relativeDirectory: { eq: "background" }
        relativePath: { regex: "/mobile/" }
      }) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(
              quality: 80
              width: 360
              breakpoints: [360, 640]
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `);

  const pcBgNode = data.allPcBackgroundImage.nodes.find(node => node.relativePath === image.pc);
  const mobileBgNode = data.allMobileBackgroundImage.nodes.find(node => node.relativePath === image.mobile);

  const mbr = () => <MobileLineBreak/>;
  const Content = (
    <SectionBase Tag="div" title={title}>
      <ContentBox>
        <Description>{$(description, { mbr })}</Description>
      </ContentBox>
    </SectionBase>
  );

  return (
    <>
      {pcBgNode && (
        <PcBackgroundImage
          id={id}
          Tag="section"
          {...convertToBgImage(getImage(pcBgNode))}
          minHeight={pcBgNode.childImageSharp.gatsbyImageData.height}
        >
          {Content}
        </PcBackgroundImage>
      )}
      {mobileBgNode && (
        <MobileBackgroundImage
          id={id}
          Tag="section"
          {...convertToBgImage(getImage(mobileBgNode))}
          minHeight={mobileBgNode.childImageSharp.gatsbyImageData.height}
        >
          {Content}
        </MobileBackgroundImage>
      )}
    </>
  );
};

export default BackgroundImageSection;

