import React, { memo } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import { rem } from 'polished';
import styled from '@emotion/styled';
import css from '@styled-system/css';

import { scale } from '~/src/css';

import { CarouselProps, CarouselMode } from './types';
import FeatureCard from './FeatureCard';

const Container = styled('div')(
  css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    overflowX: 'hidden',
  }),
);

const DotsContainer = styled('div')(
  css({
    height: [scale(125), scale(125), rem(110)],
  }),
);

const Slides = styled('div')(
  (props: {
    children: React.ReactNode[],
    mode: CarouselMode,
  }) => css({
    display: 'flex',
    overflowY: 'hidden',
    overflowX: 'auto',
    width: '100%',
    scrollSnapType: 'x mandatory',
    WebkitOverflowScrolling: 'touch',
    '> *': {
      flex: '0 0 auto',
      scrollSnapAlign: 'center',
    },
    ':before, :after': {
      content: '""',
      paddingX: props.mode === 'landscape'
        ? [scale(580 / 8), scale(580 / 8), `${800 / 8}px`]
        : [scale(500 / 8), scale(500 / 8), `${440 / 8}px`],
    },
  })(props),
);

const NativeCarousel: React.FC<CarouselProps> = ({
  items,
  mode,
}) => {
  return (
    <Container>
      <DotsContainer>
        <BeatLoader/>
      </DotsContainer>
      <Slides mode={mode}>
        {items.map((item, index) => (
          <FeatureCard
            title={item.title}
            description={item.description}
            sharp={item.image.localFile.childImageSharp}
            key={index}
            mode={mode}
          />
        ))}
      </Slides>
    </Container>
  );
};

export default memo(NativeCarousel);
