import React, { MouseEventHandler, useCallback } from 'react';
import { rem } from 'polished';
import styled from '@emotion/styled';

import { css, fontFamily, scale } from '~/src/css';

import { ReactComponent as _DownloadIcon } from './download-icon.svg';
import { ReactComponent as _Highlight } from './highlight-left-top.svg';

interface DownloadButtonProps {
  id?: string;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const Container = styled('button')(
  css({
    cursor: 'pointer',
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#f2f2f2',
    width: [scale(173 * 0.9), rem(173 * 0.8), 173],
    height: [scale(50 * 0.9), rem(50 * 0.8), 50],
    fontSize: [scale(28 * 0.9), rem(28 * 0.8), 28],
    letterSpacing: '-0.05em',
    borderRadius: [scale(8 * 0.9), rem(8 * 0.9), 8],
    ...fontFamily('CookieRun Bold', 'ko'),
  }),
);

const DownloadIcon = styled(_DownloadIcon)(
  css({
    width: [scale(29 * 0.9), rem(29 * 0.8), 29],
    height: [scale(27 * 0.9), rem(27 * 0.8), 27],
    marginRight: [scale(15 * 0.9), rem(15 * 0.8), 15],
  }),
);

const Highlight = styled(_Highlight)(
  css({
    position: 'absolute',
    top: [scale(1 * 0.9), rem(1 * 0.8), 1],
    left: [scale(2 * 0.9), rem(2 * 0.8), 2],
    width: [scale(12.55 * 0.9), rem(12.55 * 0.8), rem(12.55)],
    height: [scale(11.56 * 0.9), rem(11.56 * 0.8), rem(11.56)],
  }),
);

const DownloadButton: React.FC<DownloadButtonProps> = ({
  id,
  className,
  onClick,
}) => {
  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(e => {
    if (onClick) {
      onClick(e);
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      e.currentTarget.blur();
    }
  }, [onClick]);

  return (
    <Container id={id} className={className} onClick={handleClick}>
      <Highlight viewBox="0 0 12.55 11.56"/>
      <DownloadIcon viewBox="0 0 29 27"/>
      다운로드
    </Container>
  );
};

export default DownloadButton;
