import React, { useState } from 'react';
import { Manager, Reference, Popper } from 'react-popper';
import styled from '@emotion/styled';
import icon from '@devsisters/ui-common/lib/icon';

import { skin } from '~/src/theme';
import { css } from '~/src/css';
import { useTexts } from '~/src/i18n';
import { useFrontmatter } from '~/src/frontmatter';
import { useOtherGames } from '~/src/games';

import DevsistersGames from '~/src/components/DevsistersGames';
import _ContentBox from '~/src/components/ContentBox';
import _LanguageSelect from '~/src/components/LanguageSelector';
import { ReactComponent as _DevsistersGamesIcon } from '~/src/images/devsisters-games.svg';

const Container = styled('div')(
  skin({
    blackSquare: css({
      background: 'primary',
      height: 40,
    }),
  }),
);

const ContentBox = styled(_ContentBox)(
  css({
    position: 'relative',
    paddingX: [undefined, '1em'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  }),
  skin({
    blackSquare: css({
      color: 'normal',
      fontSize: 16,
    }),
  }),
);

const TextButton = styled('button')(
  skin({
    blackSquare: css({
      padding: 0,
      color: 'normal',
      background: 'primary',
      fontSize: '1em',
      fontFamily: 'inherit',
      textDecoration: 'none',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
    }),
  }),
);

const TextLink = TextButton.withComponent('a');
const TextLangSelector = TextButton.withComponent(_LanguageSelect);

const DevsistersWrapper = styled('div')(
  (props: { active: boolean }) => skin({
    // @ts-ignore
    blackSquare: css({
      display: ['none', 'block'],
      zIndex: 'dim',
      width: 434,
      '> div': {
        willChange: 'transform',
        transform: props.active ? 'scaleY(1)' : 'scaleY(0)',
        transition: 'transform .1s ease-in-out',
      },
    }),
  }),
);

const DevsistersGamesButton = styled(TextButton)(
  skin({
    blackSquare: css({
      color: 'normal',
      fontSize: 16,
    }),
  }),
);

const DevsistersGamesIcon = styled(_DevsistersGamesIcon)(
  skin({
    blackSquare: css({
      color: 'normal',
      // fontSize: 16,
      width: 152,
      height: 16,
      marginTop: 4,
      '> *': {
        fill: 'currentColor',
      },
    }),
  }),
);

const DevsistersGamesCloseButton = styled('button')(
  skin({
    blackSquare: css({
      position: 'absolute',
      width: 65,
      height: 65,
      outline: 'none',
      border: 'none',
      padding: 0,
      background: 'dim',
      top: 0,
      left: 480,
      lineHeight: 0,
      cursor: 'pointer',
    }),
  }),
);

const DevsistersGamesCloseIcon = styled(icon(
  '0 0 70 70',
  <path
    d="M70 68.614L68.612 70L34.999 36.387L1.38404 70L0 68.614L33.613 35.001L0 1.38796L1.38404 0L34.999 33.615L68.612 0L70 1.38796L36.385 35.001L70 68.614Z"
  />,
))(
  skin({
    blackSquare: css({
      color: '#fff',
      fontSize: 30,
    }),
  }),
);

const RightContainer = styled.div(
  skin({
    blackSquare: css({
      display: 'inline-flex',
      alignItems: 'center',
      fontSize: 14,
      '> *': {
        marginRight: '3.5em',
      },
      '> :last-child': {
        marginRight: 0,
      },
    }),
  }),
);

const CommonNavigation: React.FC = () => {
  const t = useTexts();
  const otherGames = useOtherGames();
  const hasOtherGames = otherGames.length >= 1;

  const frontmatter = useFrontmatter();
  const csUrl = frontmatter.navigation.cs?.url;

  const [popoverOpened, setPopoverOpened] = useState(false);
  const togglePopover = () => {
    setPopoverOpened(!popoverOpened);
  };

  return (
    <Container>
      <Manager>
        <Reference>
          {({ ref }) => (
            <ContentBox ref={ref}>
              {hasOtherGames ? (
                <DevsistersGamesButton
                  onClick={togglePopover}
                  data-testid="devsisters-games-button"
                >
                  <DevsistersGamesIcon/>
                </DevsistersGamesButton>
              ) : (
                <div/>
              )}
              <RightContainer>
                {csUrl && (
                  <TextLink href={csUrl} target="_blank" className="cs-link">{t`cs`}</TextLink>
                )}
                <TextLangSelector/>
              </RightContainer>
            </ContentBox>
          )}
        </Reference>
        {hasOtherGames && (
          <Popper
            eventsEnabled
            placement="bottom-start"
          >
            {({ ref, style, placement }) => (
              <DevsistersWrapper
                ref={ref}
                style={style}
                active={popoverOpened}
                data-placement={placement}
              >
                <div>
                  <DevsistersGames onClickItem={togglePopover}/>
                  <DevsistersGamesCloseButton onClick={togglePopover}>
                    <DevsistersGamesCloseIcon/>
                  </DevsistersGamesCloseButton>
                </div>
              </DevsistersWrapper>
            )}
          </Popper>
        )}
      </Manager>
    </Container>
  );
};

export default CommonNavigation;
