import { useStaticQuery, graphql } from 'gatsby';

import { SiteMetadataQuery } from '~/src/generated/types/gatsby';

export function useSiteMetadata() {
  const data = useStaticQuery<SiteMetadataQuery>(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          siteUrl
          title
          description
        }
      }
    }
  `);
  return data.site.siteMetadata;
}
