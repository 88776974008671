import React from 'react';
import { Global, css } from '@emotion/react';

// 로컬에서 손수 최적화한 폰트를 우선 폴백으로 사용
import cookieRunBoldWoff2Url from './CookieRunBold.subset.5.woff2';
import cookieRunBoldWoffUrl from './CookieRunBold.subset.5.woff';
import cookieRunBoldOtfUrl from './CookieRunBold.subset.5.otf';

// 이건 최적화 안된 원본 폰트
import cookieRunBoldTtfUrl from './CookieRunBold.ttf';

const CookierunFont: React.FC = () => {
  return (
    <Global styles={css`
      @font-face {
        font-family: 'CookieRun Bold';
        src: local('CookieRun Bold'),
          url(${cookieRunBoldWoff2Url}) format('woff2'),
          url(${cookieRunBoldWoffUrl}) format('woff'),
          url(${cookieRunBoldOtfUrl}) format('opentype'),
          url(${cookieRunBoldTtfUrl}) format('truetype');
      }
    `}/>
  );
};

export default CookierunFont;
