import React from 'react';
import styled from '@emotion/styled';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { css } from '~/src/css';
import { skin } from '~/src/theme';
import { useFrontmatter } from '~/src/frontmatter';
import { LogoQuery } from '~/src/generated/types/gatsby';

interface BannerProps {
  className?: string;
  fluid?: boolean;
  loading?: 'lazy' | 'eager';
}

const Container = styled('a')(
  skin({
    blackSquare: css({
      display: 'inline-block',
    }),
  }),
);

const Image = styled(GatsbyImage, {})(
  css({
    width: '100%',
    height: '100%',
    margin: '0 auto',
  }),
);

const Banner: React.FC<BannerProps> = ({
  className,
  fluid = false,
  loading = 'eager',
}) => {
  const data = useStaticQuery<LogoQuery>(graphql`
    query Logo {
      allLogo: allFile(filter: { relativeDirectory: { eq: "logo" }}) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(quality: 100, height: 64, layout: FIXED, formats: [AUTO, WEBP])
          }
        }
      }
    }
  `);
  const frontmatter = useFrontmatter();
  const { banner } = frontmatter.navigation;

  const logoNode = data.allLogo.nodes.find(node => node.relativePath === banner.image);
  if (!logoNode) {
    throw new Error('로고 파일 경로를 다시 확인하세요!');
  }

  return (
    <Container
      role="banner"
      href={banner.url}
      className={className}
    >
      <Image
        alt={'banner'}
        loading={loading}
        objectFit="contain"
        image={logoNode.childImageSharp.gatsbyImageData}
      />
    </Container>
  );
};

export default Banner;
