import { GatsbyBrowser } from 'gatsby';

export const onClientEntry: GatsbyBrowser['onClientEntry'] = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer');
    console.log('IntersectionObserver polyfill enabled');
  }

  if (navigator.userAgent.includes('Trident')) {
    const smoothscroll = await import('smoothscroll-polyfill');
    smoothscroll.polyfill();
    console.log('SmoothScroll polyfill enabled');
  }

  if (typeof window.requestIdleCallback === 'undefined') {
    // @ts-ignore
    await import('requestidlecallback-polyfill');
    console.log('requestIdleCallback polyfill enabled');
  }

  if (window.NodeList && !NodeList.prototype.forEach) {
    // @ts-ignore
    NodeList.prototype.forEach = Array.prototype.forEach;
    console.log('NodeList.prototype.forEach polyfill enabled');
  }
};
