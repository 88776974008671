import { graphql, useStaticQuery } from 'gatsby';

import { GamesDataQuery } from '~/src/generated/types/gatsby';
import { useLocalizedNode } from '~/src/i18n';
import { useFrontmatter } from '~/src/frontmatter';

export function useDevsistersGames() {
  const data = useStaticQuery<GamesDataQuery>(graphql`
    query GamesData {
      allCompanyInfo {
        nodes {
          locale
          games {
            id
            path
            title
            description
            status
            image
            ageNotice
          }
        }
      }
    }
  `);
  const { games } = useLocalizedNode(data.allCompanyInfo.nodes);
  return games;
}

export function useCurrentGame() {
  const games = useDevsistersGames();
  const { gameId } = useFrontmatter();
  return games.find(game => game.id === gameId);
}

export function useOtherGames() {
  const games = useDevsistersGames();
  const { gameId } = useFrontmatter();
  return games.filter(game => game.id !== gameId);
}

