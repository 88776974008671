import React from 'react';
import styled from '@emotion/styled';

import { skin } from '~/src/theme';
import { css } from '~/src/css';

import _ContentBox from '~/src/components/ContentBox';
import _Banner from '~/src/components/Banner';

interface NavBarProps {
  leftChildren?: React.ReactNode;
  rightChildren?: React.ReactNode;
  className?: string;
}

const Container = styled('div')(
  skin({
    blackSquare: css({
      background: 'navbar',
      height: 'navbar',
    }),
  }),
);

const ContentBox = styled(_ContentBox)(
  skin({
    blackSquare: css({
      paddingX: 20,
      display: 'flex',
      justifyContent: 'space-between',
      height: '100%',
    }),
  }),
);

const Banner = styled(_Banner)(
  skin({
    blackSquare: css({
      width: 'auto',
      height: '80%',
      marginRight: 10,
    }),
  }),
);

const MenuWrapper = styled('div')(
  skin({
    blackSquare: css({
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    }),
  }),
);

const NavigationBar: React.FC<NavBarProps> = ({
  leftChildren,
  rightChildren,
  className,
}) => {
  return (
    <Container className={className}>
      <ContentBox>
        <MenuWrapper>
          <Banner className='home-link'/>
          {leftChildren}
        </MenuWrapper>
        <MenuWrapper>
          {rightChildren}
        </MenuWrapper>
      </ContentBox>
    </Container>
  );
};

export default NavigationBar;
