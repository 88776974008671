import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import formatDate from 'date-fns/format';
import { rem } from 'polished';
import styled from '@emotion/styled';
import { useLocale } from '@devsisters/gatsby-plugin-i18n';
import { DevsistersCi as _DevsistersCi } from '@devsisters/ui-common/lib/identity';

import { FooterDataQuery } from '~/src/generated/types/gatsby';

import { css, scaledCss } from '~/src/css';
import { skin } from '~/src/theme';
import { useTexts, useLocalizedNode } from '~/src/i18n';
import { useFrontmatter, SocialLink } from '~/src/frontmatter';

import _ContentBox from '~/src/components/ContentBox';
import _Button from '~/src/components/Button';
import SocialLinks from '~/src/components/SocialLinks';
import ageNoticeImageUrl from '~/src/images/footer/ageNoticeImage.png';
import { useCurrentGame } from '~/src/games';

interface FooterProps {
}

const Container = styled('footer')(
  skin({
    blackSquare: scaledCss({
      background: 'primary',
      paddingTop: rem(44),
      paddingBottom: rem(70),
      fontSize: [70, '70px'],
    }),
  }),
);

const ContentBox = styled(_ContentBox)(
  skin({
    blackSquare: css({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }),
  }),
);

const DevsistersCi = styled(_DevsistersCi)(
  skin({
    blackSquare: scaledCss({
      color: '#fff',
      fontSize: [44, '29px'],
    }),
  }),
);

const ButtonWrapper = styled('div')(
  skin({
    blackSquare: css({
      display: 'flex',
      width: ['100%', 'auto'],
      justifyContent: 'center',
      flexDirection: ['column', 'row'],
      marginBottom: 13,
    }),
  }),
);

const ButtonLink = styled(_Button.withComponent('a'))(
  (props: { bold: boolean }) => skin({
    blackSquare: css({
      marginX: [10, 10],
      marginY: [9, 0],
      fontSize: [16, 16],
      paddingX: [30, 30],
      paddingY: [8, 11],
      lineHeight: ['normal', 1],
      fontWeight: props.bold ? 'bold' : 'noraml',
    }),
  }),
);

const InfoWrapper = styled('div')(
  skin({
    blackSquare: css({
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
    }),
  }),
);

const InfoText = styled('span')(
  skin({
    blackSquare: css({
      fontSize: 16,
      marginX: 5,
      marginY: 3,
      textAlign: 'center',
      color: 'primary',
    }),
  }),
);

const AgeNoticeWrapper = styled('div')(
  skin({
    blackSquare: css({
      flexDirection: ['column', 'row'],
      alignItems: ['center', 'initial'],
      display: 'flex',
      marginTop: rem(60),
    }),
  }),
);

const AgeNotiveImg = styled('img')(
  skin({
    blackSquare: css({
      marginBottom: [rem(25), 0],
      width: rem(92),
      height: rem(92),
    }),
  }));

const AgeNoticeList = styled('ul')({
  fontSize: rem(14),
  listStyle: 'none',
  padding: 0,
  margin: 0,
  marginLeft: rem(12),
});

const AgeNotiveListItem = styled('li')({
  color: 'white',
  marginBottom: rem(5),
});

const Footer: React.FC<FooterProps> = () => {
  const t = useTexts();
  const data = useStaticQuery<FooterDataQuery>(graphql`
    query FooterData {
      allCompanyInfo {
        nodes {
          locale
          address
          tel
          fax
          terms
          privacy
        }
      }
    }
  `);
  const currentLocale = useLocale();
  const companyInfo = useLocalizedNode(data.allCompanyInfo.nodes);
  const frontmatter = useFrontmatter();
  const socials = frontmatter.footer.socials as unknown as SocialLink[] | undefined;
  const { terms, privacy } = companyInfo;
  const { cs } = frontmatter.navigation;

  const outerLinkProps = { target: '_blank', rel: 'noopener' };

  const ageNotice = useCurrentGame()?.ageNotice;

  return (
    <Container>
      <ContentBox>
        {socials && (
          <div css={css({
            marginBottom: 32,
            fontSize: [60 - (4 * Math.max(socials.length, 3)), 54, 54],
          })}>
            <SocialLinks socials={socials} />
          </div>
        )}
        <ButtonWrapper>
          <ButtonLink href={terms} {...outerLinkProps} className='footer-terms-link'>
            {t`footer.terms`}
          </ButtonLink>
          <ButtonLink href={privacy} {...outerLinkProps} bold={currentLocale === 'ko'} className='footer-privacy-link'>
            {t`footer.privacy`}
          </ButtonLink>
          {cs?.url && (
            <ButtonLink href={cs.url} {...outerLinkProps} className='footer-cs-link'>
              {t`cs`}
            </ButtonLink>
          )}
        </ButtonWrapper>

        <InfoWrapper>
          <InfoText>{companyInfo.address}</InfoText>
        </InfoWrapper>

        <InfoWrapper>
          <InfoText>{t`footer.tel`}: {companyInfo.tel}</InfoText>
          <InfoText>{t`footer.fax`}: {companyInfo.fax}</InfoText>
        </InfoWrapper>

        <InfoWrapper>
          <InfoText>CookieRun® © {formatDate(Date.now(), 'yyyy')} Devsisters Corp. All Rights Reserved.</InfoText>
        </InfoWrapper>

        <div css={css({
          display: 'inline-flex',
          marginTop: 23,
          lineHeight: 1,
        })}>
          <DevsistersCi />
        </div>
        {
          ageNotice &&
          <AgeNoticeWrapper>
            <AgeNotiveImg src={ageNoticeImageUrl} />
            <AgeNoticeList>
              {
                ageNotice.map((v, i) => {
                  return (
                    <AgeNotiveListItem key={i}>{v}</AgeNotiveListItem>
                  );
                })
              }
            </AgeNoticeList>
          </AgeNoticeWrapper>
        }
      </ContentBox>
    </Container>
  );
};

export default Footer;
