import styled from '@emotion/styled';
import { css } from '~/src/css';

const MobileLineBreak = styled('br')(
  css({
    display: ['block', 'none'],
  }),
);

export default MobileLineBreak;
